import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonDrawer from 'components/CommonDrawer';
import UnassignedTargets from './UnassignedTargets';
import EditDialog from './EditDialog';
import { DrawerTargetAssignmentsProps } from './types';
import AssignedTargets from './AssignedTargets';
import { useTargetAssignmentDrawer } from './hooks';

export function TargetAssignmentsDrawer({
  dialogTargetAssignmentIsOpen,
  drawerTargetAssignmentsIsOpen,
  closeDialogTargetAssignment,
  openDialogTargetAssignment,
  closeDrawerTargetAssignments,
  readOnly,
  saveTargetAssignment,
  unassignedTargets,
}: Readonly<DrawerTargetAssignmentsProps>): JSX.Element {
  const { t } = useTranslation();
  const {
    assignedTargetList,
    cancelAndCloseHandler,
    customFieldsList,
    emptyContainer,
    refreshAssignedTargets,
    resizeElement,
    roleList,
    setResizeElement,
    setSortedTargetIds,
    setTargetInEdit,
    setTargetInEditIsUnassigned,
    sortedTargetIds,
    targetInEdit,
    targetInEditIsUnassigned,
    userCriteria,
    userList,
  } = useTargetAssignmentDrawer(closeDrawerTargetAssignments);

  return (
    <CommonDrawer
      drawerTitle={t('capture.titles.manageAssignments')}
      drawerSubTitle={t('capture.descriptions.manageTargetAssignments')}
      drawerIsOpen={drawerTargetAssignmentsIsOpen}
      submitButtonClickHandler={() => undefined}
      submitButtonLabel={'submitButtonLabel'}
      cancelAndCloseHandler={cancelAndCloseHandler}
      cancelButtonLabel={t('capture.actions.cancel')}
      submitDisabled={false}
      submitErrorText={''}
      footerComponent={
        <UnassignedTargets
          openDialogTargetAssignment={openDialogTargetAssignment}
          readOnly={readOnly}
          ref={setResizeElement}
          refreshAssignedTargets={refreshAssignedTargets}
          saveTargetAssignment={saveTargetAssignment}
          setTargetInEdit={setTargetInEdit}
          setTargetType={() => setTargetInEditIsUnassigned(true)}
          unassignedTargets={unassignedTargets}
        />
      }
    >
      <>
        {!readOnly && targetInEdit && (
          <EditDialog
            userList={userList}
            roleList={roleList}
            customFieldsList={customFieldsList}
            onClose={closeDialogTargetAssignment}
            dialogIsOpen={dialogTargetAssignmentIsOpen}
            target={targetInEdit}
            saveTargetAssignment={saveTargetAssignment}
            userCriteria={userCriteria}
            targetInEditIsUnassigned={targetInEditIsUnassigned}
            setTargetInEdit={setTargetInEdit}
            refreshAssignedTargets={refreshAssignedTargets}
            assignedTargetList={assignedTargetList}
          />
        )}
        <AssignedTargets
          userList={userList}
          roleList={roleList}
          customFieldsList={customFieldsList}
          openDialogTargetAssignment={openDialogTargetAssignment}
          setTargetInEdit={setTargetInEdit}
          setTargetInEditIsUnassigned={setTargetInEditIsUnassigned}
          sortedTargetIds={sortedTargetIds}
          refreshAssignedTargets={refreshAssignedTargets}
          setSortedTargetIds={setSortedTargetIds}
          assignedTargetList={assignedTargetList}
          footerRect={resizeElement?.getBoundingClientRect() || null}
          readOnly={readOnly}
        />
        <div ref={emptyContainer} />
      </>
    </CommonDrawer>
  );
}

export default TargetAssignmentsDrawer;
