import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as Logo } from './acrolinx-logo.svg';
import { DRAWER_WIDTH } from './MainLayout';
import Drawer from '@mui/material/Drawer';

export const FlexBox = styled(Box)({
  display: 'flex',
});

export const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
}));

export const StyledToolbar = styled(Toolbar)(() => ({
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 70,
  },
}));

export const StyledToolbarBox = styled(Box)(() => ({
  minHeight: '70px !important',
  display: 'flex',
  alignItems: 'center',
}));

export const DrawerPaper = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    zIndex: 10,
    scrollbarWidth: 'thin',
  },
}));

export const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(8),
  paddingTop: theme.spacing(19 / 5),
}));

export const AcrolinxLogo = styled(Logo)(({ theme }) => ({
  width: theme.spacing(30),
}));

export const AppBarTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  '&.MuiTypography-h6': {
    fontSize: theme.typography.pxToRem(13),
  },
}));

export const HelpLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  '&:visited': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.common.black,
  },
  '&:focus-visible': {
    outline: 'none',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    color: theme.palette.grey[500],
  },
  '&:hover, &:focus': {
    '& svg': {
      color: '#373737',
    },
  },
}));
