import AuthContext from 'contexts/AuthContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { fetchPlainResponse, HttpMethod } from 'utils/fetch';
import {
  AssignTargetsDescription,
  StyledDrawerContent,
  TargetAssignmentsContent,
} from './styles';
import { AssignedTargetsProps } from './types';
import { TARGET_SERVICE_PATH } from 'constants/constants';
import TargetAssignmentCardList from './TargetAssignmentCardList';

/* eslint-disable react/prop-types */
export default function AssignedTargets({
  setTargetInEdit,
  openDialogTargetAssignment,
  setTargetInEditIsUnassigned,
  userList,
  roleList,
  sortedTargetIds,
  refreshAssignedTargets,
  setSortedTargetIds,
  assignedTargetList,
  footerRect,
  readOnly,
}: AssignedTargetsProps): JSX.Element {
  const { t } = useTranslation();
  const [authToken] = useContext(AuthContext);
  const [assignedTargetsHeight, setAssignedTargetsHeight] = useState<number>(0);
  const refAssignedTargets = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (refAssignedTargets.current && footerRect) {
      setAssignedTargetsHeight(
        footerRect?.top -
          (
            refAssignedTargets.current as unknown as HTMLDivElement
          )?.getBoundingClientRect()?.top
      );
    }
  }, [footerRect]);

  const onDragEnd = async function (result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const newSortedTargetIds = [...sortedTargetIds];
    newSortedTargetIds.splice(source.index, 1);
    newSortedTargetIds.splice(destination.index, 0, draggableId);

    setSortedTargetIds(newSortedTargetIds);

    await fetchPlainResponse(
      authToken,
      `${TARGET_SERVICE_PATH}/target-assignments/${draggableId}/priority?value=${
        destination.index + 1
      }`,
      { method: HttpMethod.PUT }
    );
    refreshAssignedTargets();
  };

  return (
    <StyledDrawerContent
      ref={refAssignedTargets}
      sx={(theme) => ({
        overflowY: 'scroll',
        height: `${assignedTargetsHeight}px`,
        marginRight: theme.spacing(0.8),
      })}
    >
      {!assignedTargetList.length ? (
        <TargetAssignmentsContent>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/no-target-assignments.svg`}
            alt=""
          />
          <AssignTargetsDescription>
            {t('capture.descriptions.assigningTargets')}
          </AssignTargetsDescription>
        </TargetAssignmentsContent>
      ) : (
        <TargetAssignmentCardList
          assignedTargetList={assignedTargetList}
          footerTop={footerRect?.top}
          onDragEnd={onDragEnd}
          openDialogTargetAssignment={openDialogTargetAssignment}
          readOnly={readOnly}
          roleList={roleList}
          setTargetInEdit={setTargetInEdit}
          setTargetInEditIsUnassigned={setTargetInEditIsUnassigned}
          sortedTargetIds={sortedTargetIds}
          userList={userList}
        />
      )}
    </StyledDrawerContent>
  );
}
