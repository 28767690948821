import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import {
  MediumRowGrid,
  RowGrid,
  SlimRowGrid,
  ScrollableCheckboxList,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledTypography,
  StyledWarningGrid,
  SlimRowBorderGrid,
  WarningText,
  StyledEditDialog,
  InputContainer,
} from './styles';
import OptionList from './OptionList';
import { EditDialogProps } from './types';
import CriteriaDropdown from './CriteriaDropdown';
import TextFieldAndNewAdditions from './TextFieldAndNewAdditions';
import { useEditDialog } from './hooks';
import { TEXT_TYPE } from 'sections/Targets/pages/TargetPage/constants';
import CustomTooltip from 'components/CommonTooltip';

import OptionRadioGroup from './OptionRadioGroup';
import WarningIcon from 'components/WarningIcon';
import ButtonPrimary from 'components/ButtonPrimary';
import CommonSearchFilterTextField from 'components/CommonSearchFilterTextField';
import { useOverflowText } from 'hooks/useOverflowText';
import TransWrapper from 'components/TransWrapper';

export function EditDialog({
  onClose,
  dialogIsOpen,
  target,
  saveTargetAssignment,
  userList,
  roleList,
  customFieldsList,
  targetInEditIsUnassigned,
  userCriteria,
  setTargetInEdit,
  refreshAssignedTargets,
  assignedTargetList,
}: EditDialogProps): JSX.Element {
  const { t } = useTranslation();

  const {
    addNewTextToList,
    assignedTexts,
    chosenOption,
    dataForCheckboxes,
    dataForRadioGroup,
    deleteText,
    documentCriteria,
    newAddedTextList,
    newTarget,
    notModified,
    onNewTextChange,
    preventSave,
    setChosenOption,
    setFilterKey,
    setNewAddedTextList,
    setNewTarget,
    textFieldError,
    warningCheck,
  } = useEditDialog({
    target,
    userList,
    roleList,
    customFieldsList,
    assignedTargetList,
  });

  const customFieldHasTypeText =
    dataForCheckboxes.type?.toLowerCase() === TEXT_TYPE;

  const { setElementRef, isOverflowed } = useOverflowText();

  const closeHandler = useCallback(() => {
    onClose();
    setTargetInEdit(null);
  }, [onClose, setTargetInEdit]);

  return (
    <StyledEditDialog
      onClose={closeHandler}
      open={dialogIsOpen}
      fullWidth
      sx={{
        inset: '',
      }}
      id="edit-target-assignment"
    >
      <StyledDialogTitle
        sx={(theme) => ({
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        })}
      >
        <CustomTooltip title={isOverflowed ? target.targetName : ''}>
          <Typography
            ref={(node: HTMLElement | null) => node && setElementRef(node)}
            variant="body1"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <TransWrapper
              i18nKey="capture.titles.editTargetAssignment"
              values={{
                targetDisplayName: target?.targetName,
              }}
              components={{
                span: <span lang={target.targetLanguageAbbreviation} />,
              }}
            />
          </Typography>
        </CustomTooltip>
      </StyledDialogTitle>
      <StyledDialogContent>
        <SlimRowGrid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          component="label"
        >
          <Grid item>
            <StyledTypography variant="body2">
              {t('capture.labels.assignedToAll')}
            </StyledTypography>
          </Grid>
          <Grid item>
            <Switch
              checked={newTarget?.assignedToAll}
              color="primary"
              name="assign-to-all-switch"
              size="small"
              disableRipple={true}
              onChange={(_ev, active) => {
                setChosenOption('');
                setNewTarget({ ...newTarget, assignedToAll: active });
              }}
            />
          </Grid>
        </SlimRowGrid>
        <RowGrid container>
          <CriteriaDropdown
            chosenOption={chosenOption}
            setChosenOption={setChosenOption}
            userCriteria={userCriteria}
            documentCriteria={documentCriteria}
            targetIsAssignedToAll={newTarget.assignedToAll}
          />
        </RowGrid>
        {chosenOption && (
          <>
            {customFieldHasTypeText && (
              <>
                <SlimRowBorderGrid />
                <TextFieldAndNewAdditions
                  addNewTextToList={addNewTextToList}
                  newAddedTextList={newAddedTextList}
                  setNewAddedTextList={setNewAddedTextList}
                  textFieldError={textFieldError}
                  onNewTextChange={onNewTextChange}
                  assignedTexts={assignedTexts}
                  chosenOption={chosenOption}
                />
              </>
            )}
            {chosenOption === 'username' && (
              <>
                <SlimRowBorderGrid />
                <InputContainer>
                  <CommonSearchFilterTextField
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      setFilterKey(evt.target.value);
                    }}
                    placeholder={t('shared.components.input.placeholderSearch')}
                    fullWidth
                  />
                </InputContainer>
              </>
            )}
            {!!dataForCheckboxes.options.length && <SlimRowBorderGrid />}
            <ScrollableCheckboxList>
              {!!dataForCheckboxes.options.length && (
                <MediumRowGrid>
                  <OptionList
                    data={dataForCheckboxes}
                    target={newTarget}
                    setNewTarget={setNewTarget}
                    newAddedTextList={newAddedTextList}
                    deleteText={deleteText}
                    assignedTexts={assignedTexts}
                  />
                </MediumRowGrid>
              )}
              {!!dataForRadioGroup?.options.length && (
                <>
                  <SlimRowBorderGrid />
                  <RowGrid>
                    <StyledTypography
                      variant="body2"
                      sx={(theme) => ({
                        paddingBottom: theme.spacing(3.2),
                      })}
                    >
                      {t(
                        'capture.descriptions.languageDetectionInTargetAssignments'
                      )}
                    </StyledTypography>
                    <OptionRadioGroup
                      data={dataForRadioGroup}
                      target={newTarget}
                      setNewTarget={setNewTarget}
                    />
                  </RowGrid>
                </>
              )}
            </ScrollableCheckboxList>
          </>
        )}
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <Grid container>
          {!notModified && preventSave && !warningCheck && (
            <StyledWarningGrid role="alert" container alignContent="center">
              <Grid
                sx={(theme) => ({
                  paddingRight: theme.spacing(2),
                  boxSizing: 'content-box',
                })}
                item
              >
                <WarningIcon />
              </Grid>
              <Grid item xs={10}>
                <WarningText>
                  {t('capture.descriptions.noUserCriteriaSpecified')}
                </WarningText>
              </Grid>
            </StyledWarningGrid>
          )}
          {!notModified && warningCheck && (
            <StyledWarningGrid role="alert" container alignContent="center">
              <Grid
                sx={(theme) => ({ paddingRight: theme.spacing(2) })}
                xs={1}
                item
              >
                <WarningIcon />
              </Grid>
              <Grid item>
                <WarningText>
                  {t('capture.descriptions.noCriteriaSpecified')}
                </WarningText>
              </Grid>
            </StyledWarningGrid>
          )}
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <ButtonPrimary
                color="primary"
                variant="outlined"
                onClick={() => {
                  setTargetInEdit(null);
                  onClose();
                }}
              >
                {t('shared.actions.cancel')}
              </ButtonPrimary>
            </Grid>
            <Grid item>
              <ButtonPrimary
                style={{ marginRight: '0px' }}
                onClick={async () => {
                  await saveTargetAssignment(
                    newTarget,
                    !targetInEditIsUnassigned ? target.targetUuid : undefined
                  );
                  setTargetInEdit(null);
                  await refreshAssignedTargets();
                  onClose();
                }}
                color="primary"
                variant="contained"
                disabled={notModified || (preventSave && !warningCheck)}
              >
                {t('capture.actions.save')}
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </StyledEditDialog>
  );
}

export default EditDialog;
