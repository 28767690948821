import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Error40xAnd50xImage } from './error-40x-50x.svg';

const ErrorImage = styled(Error40xAnd50xImage)(({ theme }) => ({
  height: theme.typography.pxToRem(200),
  width: theme.typography.pxToRem(200),
}));

const ErrorTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(4),
}));

const ErrorDescription = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.pxToRem(24),
}));

interface ErrorComponentProps {
  errorImageAlt: string;
  errorTitle: string;
  errorDescription: string;
}

const CommonErrorComponent = ({
  errorImageAlt,
  errorTitle,
  errorDescription,
}: ErrorComponentProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box py={8} px={9} borderRadius="0.6rem" textAlign="center">
      <ErrorImage title={errorImageAlt} />
      <ErrorTitle variant="h1">{t(errorTitle)}</ErrorTitle>
      <ErrorDescription variant="h2">{t(errorDescription)}</ErrorDescription>
    </Box>
  );
};

export default CommonErrorComponent;
