import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

export const ListDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(4),
  marginLeft: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[300]}`,
}));
