import React from 'react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';

import { TargetAssignment } from '../../../../types';
import { Roles, User } from './types';
import { ChipWithMaxWidthProps } from './ChipWithMaxWidth';
import { useChipList } from './hooks';

export interface TargetAssignmentChipListProps {
  Chip: React.FunctionComponent<ChipWithMaxWidthProps>;
  dataList: (User | Roles)[];
  footerTop: number | undefined;
  hoverColor: string;
  labelText: string;
  target: TargetAssignment;
  targetPropertyIdsList: string[];
}

export default function TargetAssignmentChipList({
  Chip,
  dataList,
  footerTop,
  hoverColor,
  labelText,
  targetPropertyIdsList,
}: Readonly<TargetAssignmentChipListProps>) {
  const { anchorRef, close, elementIsOpen, open, placement, setResizeElement } =
    useChipList(footerTop);

  return (
    <>
      <Chip
        labelText={labelText}
        onMouseEnter={open}
        sx={{
          '&:hover, &:focus': {
            backgroundColor: hoverColor,
          },
        }}
        role="button"
        ref={anchorRef}
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            open();
          }
        }}
      />

      <Popper
        open={elementIsOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement}
        transition
        disablePortal
        aria-live="polite"
        onBlur={close}
      >
        {targetPropertyIdsList.length > 0 &&
          (({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper
                sx={(theme) => ({
                  maxHeight: theme.spacing(40),
                  overflowY: 'scroll',
                })}
              >
                <ClickAwayListener onClickAway={close}>
                  <List dense={true} ref={setResizeElement}>
                    {targetPropertyIdsList.map((uuid: string, index) => (
                      <ListItem
                        tabIndex={0}
                        key={uuid}
                        {...(!index && elementIsOpen && { autoFocus: true })}
                      >
                        <ListItemText
                          primary={
                            dataList.find((dataObj) => dataObj.uuid === uuid)
                              ?.name
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          ))}
      </Popper>
    </>
  );
}
