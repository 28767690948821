import React, { ReactElement } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageDefinitionListItem } from 'components/NavigationMenu';
import { StyledListItemButton, StyledListItemText } from './styles';
import NewBadge from '../NewBadge';
import { ListItemButtonComponent } from './ListItemButtonComponent';

type AppMenuItemComponentProps = {
  path?: string | null; // because the InferProps props allows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
} & Partial<PageDefinitionListItem>;

const NavItemComponent = ({
  path,
  onClick,
  title,
  mainMenu,
}: AppMenuItemComponentProps): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const match = useRouteMatch(mainMenu?.setSelectedRoutePaths || []);
  const selected =
    (location.pathname === path || !!match) &&
    !mainMenu?.notSetSelectedRoutePaths?.includes(location.pathname);
  const isParent = !selected && location.pathname.includes(path || '');

  if (!title) {
    return <></>;
  }

  const isClassicView = !!mainMenu?.linkToClassicView;

  return (
    <StyledListItemButton
      disableRipple
      selected={selected}
      onClick={onClick}
      className={isParent ? 'selectedParent' : ''}
      component={ListItemButtonComponent}
      to={(isClassicView ? mainMenu.linkToClassicView : path) ?? ''}
      isClassicView={isClassicView}
    >
      <StyledListItemText primary={t(title)} />
      {mainMenu?.isNew && <NewBadge />}
    </StyledListItemButton>
  );
};

export default NavItemComponent;
