import { FetchJsonReturnType, requestResponseOrThrowError } from 'utils/fetch';
import { Privileges, User } from 'sections/Users/types';
import { FeatureSwitches } from 'types/types';
import { useQuery } from '@tanstack/react-query';
import AuthContext from 'contexts/AuthContext';
import { useContext } from 'react';
import {
  FEATURES_PATH,
  PRIVILEGES_PATH,
  USER_API_PATH,
} from 'constants/constants';

interface PrivilegesResponse extends FetchJsonReturnType {
  data: {
    privileges: Privileges;
  };
}

interface FeaturesResponse extends FetchJsonReturnType {
  data: {
    features: FeatureSwitches;
  };
}

interface CurrentUserResponse extends FetchJsonReturnType {
  data: User;
}

export function usePrivateContent() {
  const [authToken] = useContext(AuthContext);

  const { data: privilegesData, error: errorGetPrivileges } = useQuery<
    PrivilegesResponse,
    Error
  >({
    queryKey: ['userPrivileges'],
    queryFn: () => requestResponseOrThrowError(authToken, PRIVILEGES_PATH),
  });

  const { data: featuresData, error: errorGetFeatures } = useQuery<
    FeaturesResponse,
    Error
  >({
    queryKey: ['features'],
    queryFn: () => requestResponseOrThrowError(authToken, FEATURES_PATH),
  });

  const { data: currentUserData } = useQuery<CurrentUserResponse, Error>({
    queryKey: ['currentUser'],
    queryFn: () =>
      requestResponseOrThrowError(authToken, `${USER_API_PATH}/self`),
  });

  return {
    currentUserData,
    errorGetFeatures,
    errorGetPrivileges,
    featuresData,
    privilegesData,
  };
}
