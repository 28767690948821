import React, { ReactElement, useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import IconButton from '@mui/material/IconButton';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';

import useTitle from 'hooks/useTitle';
import ErrorHandler from 'pages/ErrorHandler';
import NotFoundErrorPage from 'pages/NotFoundErrorPage';
import BusyIndicator from 'components/BusyIndicator';
import CreateOrEditTargetDrawer from 'sections/Targets/components/CreateOrEditTargetDrawer';

import CopyTargetDialog from './components/CopyTargetDialog';
import DeleteTargetDialog from './components/DeleteTargetDialog';
import TargetAssignmentsDrawer from './components/TargetAssignments';
import TargetTable from './components/TargetTable';
import CommonStartPageNoData from 'components/CommonStartPage';
import { useTargetIndexPageHook } from './hooks';
import RecentActivitiesDrawer from './components/RecentActivitiesDrawer';

import { StyledBadge } from './styles';
import ButtonPrimary from 'components/ButtonPrimary';
import { TargetTableProps } from 'sections/Targets/types';
import { LanguageFull, TargetIndexItem } from 'types/types';
import ButtonAddIcon from 'icons/ButtonAddIcon';

import IndexPageLayout from 'components/PageLayout/IndexPageLayout';
import { MainBoxPart } from 'components/PageLayout/components';
import TargetAssignmentDocs from './components/TargetAssignmentDocs';
import { useIsOpen } from 'hooks/useIsOpen';
import { useFetchLanguages } from 'hooks/useFetchLanguages';
import CommonTooltip from 'components/CommonTooltip';
import CommonLockIconButton from 'components/CommonLockIconButton';
import ConditionalWrapper from 'components/ConditionalWrapper';

export default function TargetPageWrapper(): ReactElement {
  return <TargetIndexPage />;
}

export const TargetIndexPage = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    closeDeleteConfirmationDialog,
    closeDialogTargetAssignment,
    closeDrawerCreateTarget,
    closeDrawerTargetAssignments,
    closeForCopyTargetDialog,
    createTarget,
    dialogTargetAssignmentIsOpen,
    drawerCreateTargetIsOpen,
    drawerTargetAssignmentsIsOpen,
    forCopyTargetDialog,
    openDialogTargetAssignment,
    openDrawerTargetAssignments,
    privileges: { editRights, readRights },
    saveTargetAssignment,
    setDrawerCreateTargetIsOpen,
    targetForDeleteConfirmation,
    targetsResult,
    unassignedTargets,
    ...targetTableProps
  } = useTargetIndexPageHook();

  const { data: languages } = useFetchLanguages<LanguageFull>('full');

  const headingRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  const {
    close: closeTargetAssignmentDocs,
    elementIsOpen: targetAssignmentDocsIsOpen,
    open: openTargetAssignmentDocs,
  } = useIsOpen();
  const {
    close: closeTargetsRecentActivities,
    elementIsOpen: targetsRecentActivitiesIsOpen,
    open: openTargetsRecentActivities,
  } = useIsOpen();

  useTitle(`${t('capture.titles.targets')} - ${t('shared.app.name')}`);
  if (targetsResult.error) {
    return <ErrorHandler error={targetsResult.error} />;
  }

  const localFeatureFlagForDocs = !!localStorage.getItem(
    'localFeatureFlagForDocsHackaton2023'
  );

  // Access control based on privileges
  if (!editRights && !readRights) {
    return <NotFoundErrorPage />;
  }

  return (
    <div>
      <TargetAssignmentsDrawer
        drawerTargetAssignmentsIsOpen={
          drawerTargetAssignmentsIsOpen || dialogTargetAssignmentIsOpen
        }
        dialogTargetAssignmentIsOpen={dialogTargetAssignmentIsOpen}
        closeDrawerTargetAssignments={closeDrawerTargetAssignments}
        closeDialogTargetAssignment={closeDialogTargetAssignment}
        openDialogTargetAssignment={openDialogTargetAssignment}
        readOnly={!editRights}
        saveTargetAssignment={saveTargetAssignment}
        unassignedTargets={unassignedTargets}
      />
      {targetsRecentActivitiesIsOpen && (
        <RecentActivitiesDrawer
          drawerIsOpen={targetsRecentActivitiesIsOpen}
          drawerTitle={t('capture.titles.recentActivities')}
          drawerSubTitle={t('capture.descriptions.recentActivitiesDescription')}
          cancelAndCloseHandler={closeTargetsRecentActivities}
        />
      )}
      <IndexPageLayout
        heading={t('capture.titles.targets')}
        focusHeading
        helpIconText={t(
          editRights
            ? 'capture.actions.tooltips.targetsInfo'
            : 'capture.actions.tooltips.targetsInfoReadOnly'
        )}
        headerRightSideContent={
          <Box>
            {localFeatureFlagForDocs && (
              <>
                <IconButton onClick={openTargetAssignmentDocs}>
                  <ImportContactsIcon
                    sx={(theme) => ({ color: theme.palette.primary.main })}
                  />
                </IconButton>
                <TargetAssignmentDocs
                  close={closeTargetAssignmentDocs}
                  targetAssignmentDocsIsOpen={targetAssignmentDocsIsOpen}
                />
              </>
            )}
            <ConditionalWrapper
              condition={editRights}
              wrapper={(children) => (
                <StyledBadge badgeContent={unassignedTargets.length}>
                  {children}
                </StyledBadge>
              )}
            >
              <ButtonPrimary
                variant="contained"
                color="primary"
                onClick={openDrawerTargetAssignments}
              >
                {t(
                  editRights
                    ? 'capture.actions.manageAssignments'
                    : 'capture.actions.viewAssignments'
                )}
              </ButtonPrimary>
            </ConditionalWrapper>
          </Box>
        }
      >
        <MainBoxPart>
          {!targetsResult.data ? (
            <Box pt={20} pb={17}>
              <BusyIndicator />
            </Box>
          ) : (
            <>
              {editRights ? (
                <>
                  <CreateOrEditTargetDrawer
                    submit={createTarget}
                    drawerIsOpen={drawerCreateTargetIsOpen}
                    close={closeDrawerCreateTarget}
                    targetsNames={targetsResult.data?.map(
                      (x: TargetIndexItem) => x.displayName
                    )}
                    drawerTitle={t('capture.titles.createNewTarget')}
                    submitButtonLabel={t('capture.actions.createTarget')}
                    languages={languages}
                  />
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={(theme) => theme.spacing(4)}
                  >
                    <Grid item alignItems="center">
                      <ButtonPrimary
                        variant="contained"
                        onClick={() => setDrawerCreateTargetIsOpen(true)}
                        aria-label={t('capture.actions.createNewTarget')}
                      >
                        <ButtonAddIcon />
                        {t('capture.actions.createNewTarget')}
                      </ButtonPrimary>
                    </Grid>
                    <Grid item alignItems="center">
                      <CommonTooltip
                        title={t('capture.tooltips.recentActivities')}
                      >
                        <IconButton
                          onClick={openTargetsRecentActivities}
                          sx={(theme) => ({
                            '&:hover': {
                              backgroundColor: theme.palette.primary.light,
                            },
                          })}
                        >
                          <HistoryRoundedIcon color="primary" />
                        </IconButton>
                      </CommonTooltip>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container justifyContent="flex-end">
                  <CommonLockIconButton
                    tooltipText={t('capture.tooltips.readonly')}
                  />
                </Grid>
              )}
              {targetsResult.data.length === 0 ? (
                <CommonStartPageNoData
                  title={t('capture.titles.noData')}
                  description={t('capture.descriptions.noData')}
                />
              ) : (
                <>
                  {editRights && forCopyTargetDialog && (
                    <CopyTargetDialog
                      target={forCopyTargetDialog}
                      close={closeForCopyTargetDialog}
                      targetsNames={targetsResult.data?.map(
                        (x: TargetIndexItem) => x.displayName
                      )}
                    />
                  )}
                  {editRights && targetForDeleteConfirmation && (
                    <DeleteTargetDialog
                      target={targetForDeleteConfirmation}
                      onClose={closeDeleteConfirmationDialog}
                    />
                  )}
                  <TargetTable
                    {...(targetTableProps as TargetTableProps)}
                    showIcons={editRights}
                  />
                </>
              )}
            </>
          )}
        </MainBoxPart>
      </IndexPageLayout>
    </div>
  );
};
