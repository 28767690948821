import { ElementType } from 'react';
import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import Grid, { GridProps } from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import Typography, { TypographyProps } from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';

import { DrawerTypeInterface } from './CommonDrawer';

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'type',
})<DrawerProps & DrawerTypeInterface>(({ theme, type = 'slim' }) => ({
  '& .MuiPaper-root.MuiDrawer-paper': {
    width: type === 'slim' ? theme.spacing(70) : '50%',
    boxShadow: '-6px 0px 6px 0px rgba(60, 60, 60, 0.2)',
    overflow: 'hidden',
  },
}));

export const DrawerFooter = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'type',
})<GridProps & DrawerTypeInterface>(({ theme, type = 'slim' }) => ({
  width: type === 'slim' ? theme.spacing(70) : '50%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(3.4, 2, 3.4, 3),
  overflow: 'hidden',
  borderTop: `1px solid ${blueGrey[100]}`,
  position: 'fixed',
  bottom: 0,
  '& button': {
    marginRight: theme.spacing(2),
    zIndex: 1302,
  },
  zIndex: 1301,
  backgroundColor: theme.palette.background.paper,
}));

export const DrawerContentWrapper = styled('div')({
  overflow: 'hidden',
});

export const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: blueGrey[200],
  padding: theme.spacing(0.5),
  width: '42px',
  height: '42px',
  zIndex: 2,
  '& svg': {
    fontSize: theme.typography.pxToRem(24),
  },
  '&:hover': {
    color: theme.palette.custom.marengo,
    backgroundColor: theme.palette.custom.aliceBlue,
  },
}));

export const DrawerContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(3.2),
}));

export const StyledDrawerTitle = styled(Typography)<
  TypographyProps<ElementType, { component?: ElementType }>
>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.36),
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.pxToRem(27),
}));

export const StyledDrawerSubtitle = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(21),
  padding: theme.spacing(2, 2, 3, 0),
}));

export const TitleGrid = styled(Grid)(({ theme }) => ({
  minHeight: theme.spacing(14),
  padding: theme.spacing(2, 2, 0, 4),
  backgroundColor: theme.palette.common.white,
}));

export const CommonDrawerFieldTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.gray36,
  textAlign: 'left',
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(18),
  letterSpacing: theme.typography.pxToRem(0.39),
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
}));

export const AdvancedSettingsSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(20),
  letterSpacing: theme.typography.pxToRem(0.32),
  textAlign: 'left',
  fontWeight: theme.typography.fontWeightMedium,
}));

export const InnerDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.custom.lighterGrey,
}));

// DrawerContent is a styled div
export const ScrollableDrawerContent = styled(DrawerContent)(() => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  boxShadow: 'none',
  '& button': {
    margin: 0,
  },
}));
