import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NewAddedText } from 'sections/Targets/pages/TargetPage/types';
import {
  DataForCheckboxes,
  DataForRadioGroup,
  Options,
  Roles,
  User,
  UserCriterion,
} from './types';
import {
  DOCUMENT_CUSTOM_FIELD_CRITERIA_PATH,
  DOCUMENT_CUSTOM_FIELDS_PATH,
  DOCUMENT_REFERENCE_CRITERIA_PATH,
  ROLES_PATH,
  TARGET_ASSIGNMENT_INDEX_PATH,
  TEXT_TYPE,
  USER_CUSTOM_FIELDS_PATH,
  USERS_PATH,
} from 'sections/Targets/pages/TargetPage/constants';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import {
  areDataForCheckboxesObjectsEqual,
  filterByKeyword,
  getPropertyValue,
} from './helpers';
import {
  fetchJsonFromAPI,
  HttpMethod,
  requestResponseOrThrowError,
} from 'utils/fetch';
import AuthContext from 'contexts/AuthContext';
import useResize from 'hooks/useResize';
import { CustomField, TargetAssignment } from 'sections/Targets/types';
import {
  ASSIGNED_TARGETS_QUERY_KEY,
  CUSTOM_FIELDS_QUERY_KEY,
  ROLES_QUERY_KEY,
  TARGET_DOCUMENT_CUSTOM_FIELDS_QUERY_KEY,
  TARGET_DOCUMENT_REFERENCE_QUERY_KEY,
  TARGET_REGEXES_QUERY_KEY,
  TARGET_SERVICE_PATH,
  USERS_QUERY_KEY,
} from 'constants/constants';
import { useQuery } from '@tanstack/react-query';
import { useIsOpen } from '../../../../../../hooks/useIsOpen';
import { PopperPlacementType } from '@mui/material';

export const blankDataForCheckboxes: DataForCheckboxes = {
  options: [],
  allPropertyName: '',
  propertyName: '',
  onChange: undefined,
  getPropertyValue: undefined,
  customFieldName: '',
};

export const blankDataForRadioGroup: DataForRadioGroup = {
  options: [],
};

export interface UseEditDialogProps {
  target: TargetAssignment;
  userList: User[];
  roleList: Roles[];
  customFieldsList: CustomField[];
  assignedTargetList: TargetAssignment[];
}

interface UseEditDialogResult {
  addNewTextToList: (regex: string) => void;
  assignedTexts: Set<string>;
  chosenOption: string;
  dataForCheckboxes: DataForCheckboxes;
  dataForRadioGroup: DataForRadioGroup;
  deleteText: (text: string) => void;
  documentCriteria: Options[];
  newAddedTextList: NewAddedText[];
  newTarget: TargetAssignment;
  notModified: boolean;
  onNewTextChange: (text: string) => void;
  preventSave: boolean;
  setNewAddedTextList: (list: NewAddedText[]) => void;
  setNewTarget: (target: TargetAssignment) => void;
  setChosenOption: (option: string) => void;
  setFilterKey: (filterKey: string) => void;
  textFieldError: string;
  warningCheck: boolean;
}

export function useEditDialog({
  assignedTargetList,
  customFieldsList,
  roleList,
  target,
  userList,
}: UseEditDialogProps): UseEditDialogResult {
  const { t } = useTranslation();
  const [authToken] = useContext(AuthContext);
  const [newTarget, setNewTarget] = useState<TargetAssignment>(target);
  const [chosenOption, setChosenOption] = useState<string>('');
  const [regexList, setRegexList] = useState<CustomField[]>([]);
  const [documentReferenceList, setDocumentReferenceList] = useState<string[]>(
    []
  );
  const [assignedTexts, setAssignedTexts] = useState<Set<string>>(new Set());
  const [newAddedTextList, setNewAddedTextList] = useState<NewAddedText[]>([]);
  const [documentCustomFieldsList, setDocumentCustomFieldsList] =
    React.useState<CustomField[]>([]);

  const initialDocumentCriteria = [
    { id: 'documentReference', label: t('capture.labels.documentReference') },
    { id: 'languageDetection', label: t('capture.labels.languageDetection') },
  ];
  const [documentCriteria, setDocumentCriteria] = useState<Options[]>(
    initialDocumentCriteria
  );

  const [dataForCheckboxes, setDataForCheckboxes] =
    React.useState<DataForCheckboxes>({ ...blankDataForCheckboxes });
  const [dataForRadioGroup, setDataForRadioGroup] =
    React.useState<DataForRadioGroup>({ ...blankDataForRadioGroup });
  const [textFieldError, setTextFieldError] = useState<string>('');

  const [filterKey, setFilterKey] = useState<string>('');

  async function fetchDocumentReference() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${DOCUMENT_REFERENCE_CRITERIA_PATH}`
    );
  }
  const documentReferenceResult = useQuery<string[], Error>({
    queryKey: [TARGET_DOCUMENT_REFERENCE_QUERY_KEY],
    queryFn: fetchDocumentReference,
  });

  async function fetchDocumentCustomFields() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${DOCUMENT_CUSTOM_FIELDS_PATH}`
    );
  }
  const documentCustomFieldsResult = useQuery<CustomField[], Error>({
    queryKey: [TARGET_DOCUMENT_CUSTOM_FIELDS_QUERY_KEY],
    queryFn: fetchDocumentCustomFields,
  });

  async function fetchRegexes() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${DOCUMENT_CUSTOM_FIELD_CRITERIA_PATH}`
    );
  }
  const regexResult = useQuery<CustomField[], Error>({
    queryKey: [TARGET_REGEXES_QUERY_KEY],
    queryFn: fetchRegexes,
  });

  function onNewTextChange(newText: string) {
    const newTextAlreadyInOptions =
      dataForCheckboxes.propertyName === 'documentReferenceCriteria'
        ? documentReferenceList.includes(newText)
        : !!regexList
            .filter(
              (customField) =>
                customField.name === dataForCheckboxes.customFieldName
            )
            .find(
              (customField) =>
                dataForCheckboxes.optionsFieldName &&
                customField[dataForCheckboxes.optionsFieldName]?.includes(
                  newText
                )
            );

    if (newTextAlreadyInOptions) {
      setTextFieldError(t('capture.hints.duplicatedRegexp'));
      return; // todo add information or move new text to newTextList and remove from target
    }

    setTextFieldError('');
  }

  function addNewTextToList(newText: string) {
    const textSet = new Set(newAddedTextList.map((textObj) => textObj.text));
    textSet.add(newText);
    const newRegexps = Array.from(textSet).map((text) => ({
      text,
      checked:
        newAddedTextList.find((textObj) => textObj.text === text)?.checked ||
        true,
    }));

    setNewAddedTextList(newRegexps);
  }

  useEffect(() => {
    if (dataForCheckboxes.propertyName) {
      let propertyArray: (CustomField | string)[] = [];
      const uncheckedNewAddedTexts = newAddedTextList
        .filter((textObj) => !textObj.checked)
        .map((textObj) => textObj.text);

      if (dataForCheckboxes.propertyName === 'documentReferenceCriteria') {
        propertyArray = Array.from(
          new Set(
            [...newTarget[dataForCheckboxes.propertyName]]
              .filter((text) => !uncheckedNewAddedTexts.includes(text))
              .concat(
                newAddedTextList
                  .filter((textObj) => textObj.checked)
                  .map((textObj) => textObj.text)
              )
          )
        );
      } else {
        if (
          dataForCheckboxes.customFieldName &&
          dataForCheckboxes.optionsFieldName
        ) {
          let propertyIndex = (
            newTarget[dataForCheckboxes.propertyName] as CustomField[]
          ).findIndex(
            (customField: CustomField) =>
              customField.name === dataForCheckboxes.customFieldName
          );

          let propertyObj: CustomField = {
            ...(target[dataForCheckboxes.propertyName] as CustomField[])[
              propertyIndex
            ],
          };

          if (propertyIndex === -1) {
            propertyIndex = newTarget[dataForCheckboxes.propertyName].length;
            propertyObj = {
              name: dataForCheckboxes.customFieldName,
              [dataForCheckboxes.optionsFieldName]: [],
            };
          }

          propertyObj[dataForCheckboxes.optionsFieldName] = (
            (propertyObj[dataForCheckboxes.optionsFieldName] as string[]) || []
          )
            // remove unchecked and already added
            .filter((text) => !uncheckedNewAddedTexts.includes(text))
            .concat(
              newAddedTextList
                .filter((textObj) => textObj.checked)
                .map((textObj) => textObj.text)
            );

          propertyArray = [...newTarget[dataForCheckboxes.propertyName]];
          propertyArray[propertyIndex] = propertyObj;
        }
      }

      setNewTarget({
        ...newTarget,
        [dataForCheckboxes.propertyName]: propertyArray,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [newAddedTextList]);

  useEffect(() => {
    if (chosenOption === 'username') {
      setDataForCheckboxes({
        ...blankDataForCheckboxes,
        options: filterByKeyword(userList, filterKey),
        propertyName: 'userUuids',
        allPropertyName: 'assignedToAll',
      });
    }
  }, [filterKey, userList, chosenOption]);

  useEffect(() => {
    if (regexResult?.data) {
      setRegexList(
        regexResult?.data.filter((regex: CustomField) =>
          regex.criteria?.filter(
            (regex) =>
              !newAddedTextList.find((textObj) => textObj.text === regex)
          )
        )
      );
    }
  }, [newAddedTextList, regexResult?.data, dataForCheckboxes.propertyName]);

  useEffect(() => {
    if (documentReferenceResult?.data) {
      setDocumentReferenceList(documentReferenceResult.data);
    }
  }, [documentReferenceResult?.data]);

  useEffect(() => {
    if (chosenOption === 'languageDetection') {
      if (dataForCheckboxes.options.length) {
        setDataForCheckboxes({
          ...blankDataForCheckboxes,
        });
      }
      setDataForRadioGroup({
        ...blankDataForRadioGroup,
        options: [
          {
            label: t('capture.labels.languageDetectionRadioButtons.on'),
            value: 'on',
            key: '1',
          },
          {
            label: t('capture.labels.languageDetectionRadioButtons.off'),
            value: 'off',
            key: '2',
          },
        ],
      });
    } else if (dataForRadioGroup?.options.length) {
      setDataForRadioGroup({ ...blankDataForRadioGroup });
    }
  }, [
    chosenOption,
    dataForRadioGroup?.options.length,
    dataForCheckboxes.options.length,
    t,
  ]);

  useEffect(() => {
    if (documentCustomFieldsResult.data) {
      setDocumentCustomFieldsList(documentCustomFieldsResult.data);

      if (documentCustomFieldsResult.data.length) {
        setDocumentCriteria(
          initialDocumentCriteria.concat(
            documentCustomFieldsResult.data
              .map((customField: CustomField) => ({
                id: `${customField.name}|document_custom_field`,
                label: customField.name,
              }))
              .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
              )
          )
        );
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [documentCustomFieldsResult.data]);

  function createCustomFieldConfig(
    target: TargetAssignment,
    propertyName: DataForCheckboxes['propertyName']
  ) {
    const name = chosenOption.split('|')[0];

    const customFieldFromList = documentCustomFieldsList.find(
      (documentCustomField) => documentCustomField.name === name
    );

    const typeAddition =
      propertyName === 'documentCustomFieldCriteria' &&
      customFieldFromList?.type?.toLowerCase() === TEXT_TYPE
        ? { type: customFieldFromList?.type }
        : {};

    const dictionaryObj =
      (propertyName === 'customFields'
        ? customFieldsList
        : documentCustomFieldsList
      ).find((customField) => customField.name === name) || ({} as CustomField);

    const optionBase =
      propertyName === 'documentCustomFieldCriteria' &&
      typeAddition.type?.toLowerCase() === TEXT_TYPE
        ? regexList
            .filter((customField: CustomField) => customField.name === name)
            .reduce((acc: string[], customField: CustomField) => {
              return acc.concat(customField.criteria || []);
            }, [])
        : dictionaryObj?.options;

    const optionsFieldName =
      propertyName === 'customFields' ? 'options' : 'criteria';

    const newData = {
      ...blankDataForCheckboxes,
      propertyName,
      customFieldName: name,
      getPropertyValue: getPropertyValue.bind(null, propertyName, name),
      optionsFieldName,
      options:
        optionBase?.map((opt) => ({
          id: opt,
          label: opt,
        })) || [],
      ...typeAddition,
    } as DataForCheckboxes;

    if (
      !areDataForCheckboxesObjectsEqual(dataForCheckboxes, newData, [
        'getPropertyValue',
      ])
    ) {
      setDataForCheckboxes(newData);
    }
  }

  useEffect(() => {
    switch (chosenOption) {
      case 'username':
        const newUsernameData = {
          ...blankDataForCheckboxes,
          options: filterByKeyword(userList, filterKey),
          propertyName: 'userUuids',
          allPropertyName: 'assignedToAll',
        } as DataForCheckboxes;
        if (
          !areDataForCheckboxesObjectsEqual(dataForCheckboxes, newUsernameData)
        ) {
          setDataForCheckboxes(newUsernameData);
        }
        return;
      case 'role':
        const newRoleData = {
          ...blankDataForCheckboxes,
          options: roleList.map(
            (role): Options => ({ id: role.uuid, label: role.name })
          ),
          propertyName: 'roleUuids',
        } as DataForCheckboxes;
        if (!areDataForCheckboxesObjectsEqual(dataForCheckboxes, newRoleData)) {
          setDataForCheckboxes(newRoleData);
        }
        return;
      case chosenOption.match(/document_custom_field$/)?.input: {
        createCustomFieldConfig(target, 'documentCustomFieldCriteria');
        return;
      }
      case chosenOption.match(/user_custom_field$/)?.input: {
        createCustomFieldConfig(target, 'customFields');
        return;
      }
      case 'documentReference':
        const newDocumentReferenceData = {
          ...blankDataForCheckboxes,
          options: documentReferenceList.map(
            (ref): Options => ({ id: ref, label: ref })
          ),
          propertyName: 'documentReferenceCriteria',
          type: TEXT_TYPE,
        } as DataForCheckboxes;
        if (
          !areDataForCheckboxesObjectsEqual(
            dataForCheckboxes,
            newDocumentReferenceData
          )
        ) {
          setDataForCheckboxes(newDocumentReferenceData);
        }

        return;
      default:
        if (dataForCheckboxes.options.length) {
          setDataForCheckboxes({
            ...blankDataForCheckboxes,
          });
        }
        return;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    chosenOption,
    userList,
    documentReferenceList,
    documentCustomFieldsResult,
    target,
    regexList,
  ]);

  useEffect(() => {
    getAssignedTexts();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [assignedTargetList, dataForCheckboxes.propertyName]);

  function getAssignedTexts() {
    if (
      dataForCheckboxes.propertyName &&
      target[dataForCheckboxes.propertyName]
    ) {
      if (
        dataForCheckboxes.optionsFieldName &&
        dataForCheckboxes.customFieldName
      ) {
        setAssignedTexts(
          assignedTargetList.reduce((acc: Set<string>, assignedTarget) => {
            if (
              dataForCheckboxes.propertyName &&
              assignedTarget[dataForCheckboxes.propertyName]
            ) {
              return new Set([
                ...acc,
                ...((
                  assignedTarget[
                    dataForCheckboxes.propertyName
                  ] as CustomField[]
                ).find(
                  (customField) =>
                    customField.name === dataForCheckboxes.customFieldName
                )?.criteria || []),
              ]);
            }

            return acc;
          }, new Set([]))
        );

        return;
      }

      setAssignedTexts(
        assignedTargetList.reduce((acc: Set<string>, assignedTarget) => {
          return new Set([
            ...acc,
            ...(dataForCheckboxes.propertyName &&
              (assignedTarget[dataForCheckboxes.propertyName] as string[])),
          ]);
        }, new Set([]))
      );
    }
  }

  async function deleteText(text: string) {
    if (text) {
      const endpointPath = dataForCheckboxes.customFieldName
        ? DOCUMENT_CUSTOM_FIELD_CRITERIA_PATH
        : DOCUMENT_REFERENCE_CRITERIA_PATH;
      const name =
        dataForCheckboxes.customFieldName &&
        `name=${dataForCheckboxes.customFieldName}&`;

      const path = `${TARGET_SERVICE_PATH}/${endpointPath}?${name}criteria=${encodeURIComponent(
        text
      )}`;

      await fetchJsonFromAPI<TargetAssignment, never>(authToken, path, {
        method: HttpMethod.DELETE,
      });

      dataForCheckboxes.customFieldName
        ? await regexResult.refetch()
        : await documentReferenceResult.refetch();
    }
  }

  const notModified = isEqual(target, newTarget);
  const preventSave =
    !newTarget.customFields.length &&
    !newTarget.assignedToAll &&
    !newTarget.roleUuids.length &&
    !newTarget.userUuids.length;

  const warningCheck =
    !newTarget.documentReferenceCriteria.length &&
    !newTarget.documentCustomFieldCriteria.length &&
    !newTarget.customFields.length &&
    !newTarget.assignedToAll &&
    !newTarget.roleUuids.length &&
    !newTarget.userUuids.length &&
    !newTarget.enableLanguageDetection;

  return {
    addNewTextToList,
    assignedTexts,
    chosenOption,
    dataForCheckboxes,
    dataForRadioGroup,
    deleteText,
    documentCriteria,
    newAddedTextList,
    newTarget,
    notModified,
    onNewTextChange,
    preventSave,
    setChosenOption,
    setFilterKey,
    setNewAddedTextList,
    setNewTarget,
    textFieldError,
    warningCheck,
  };
}

export interface UseTargetAssignmentDrawerResult {
  assignedTargetList: TargetAssignment[];
  cancelAndCloseHandler: () => void;
  customFieldsList: CustomField[];
  emptyContainer: RefObject<HTMLDivElement>;
  refreshAssignedTargets: () => void;
  resizeElement: HTMLDivElement | null;
  roleList: Roles[];
  setResizeElement: (arg: HTMLDivElement | null) => void;
  setSortedTargetIds: (arg: string[]) => void;
  setTargetInEdit: (arg: TargetAssignment | null) => void;
  setTargetInEditIsUnassigned: (arg: boolean) => void;
  sortedTargetIds: string[];
  targetInEdit: TargetAssignment | null;
  targetInEditIsUnassigned: boolean;
  userCriteria: UserCriterion[];
  userList: User[];
}

export function useTargetAssignmentDrawer(
  closeDrawerTargetAssignments: () => void
): UseTargetAssignmentDrawerResult {
  const { t } = useTranslation();
  const [authToken] = useContext(AuthContext);
  const [targetInEditIsUnassigned, setTargetInEditIsUnassigned] =
    useState(true);
  const [targetInEdit, setTargetInEdit] = useState<TargetAssignment | null>(
    null
  );

  const initialUserCriteria = [
    { id: 'role', label: t('capture.labels.role') },
    { id: 'username', label: t('capture.labels.username') },
  ];

  const [userCriteria, setUserCriteria] =
    React.useState<Options[]>(initialUserCriteria);

  const emptyContainer = useRef<HTMLDivElement>(null);
  const { setResizeElement, resizedHeight, resizeElement } =
    useResize<HTMLDivElement>();
  async function fetchAssignedTargets() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${TARGET_ASSIGNMENT_INDEX_PATH}`
    );
  }
  const assignedTargetsResult = useQuery<TargetAssignment[], Error>({
    queryKey: [ASSIGNED_TARGETS_QUERY_KEY],
    queryFn: fetchAssignedTargets,
  });
  const [assignedTargetList, setAssignedTargetList] = useState<
    TargetAssignment[]
  >([]);
  const [sortedTargetIds, setSortedTargetIds] = useState<string[]>([]);
  const [userList, setUserList] = React.useState<User[]>([]);
  async function fetchUsers() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${USERS_PATH}`
    );
  }
  const usersResult = useQuery<User[], Error>({
    queryKey: [USERS_QUERY_KEY],
    queryFn: fetchUsers,
  });
  const [roleList, setRoleList] = React.useState<Roles[]>([]);
  async function fetchRoles() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${ROLES_PATH}`
    );
  }
  const rolesResult = useQuery<Roles[], Error>({
    queryKey: [ROLES_QUERY_KEY],
    queryFn: fetchRoles,
  });
  const [customFieldsList, setCustomFieldsList] = React.useState<CustomField[]>(
    []
  );
  async function fetchCustomFields() {
    return await requestResponseOrThrowError(
      authToken,
      `${TARGET_SERVICE_PATH}/${USER_CUSTOM_FIELDS_PATH}`
    );
  }
  const customFieldsResult = useQuery<CustomField[], Error>({
    queryKey: [CUSTOM_FIELDS_QUERY_KEY],
    queryFn: fetchCustomFields,
  });

  useEffect(() => {
    if (assignedTargetsResult.data) {
      setAssignedTargetList(assignedTargetsResult.data);
    }
  }, [assignedTargetsResult.data]);

  useEffect(() => {
    try {
      const sortedAssignedTargets = assignedTargetList?.sort((a, b) => {
        return a.priority - b.priority;
      });
      setSortedTargetIds(
        sortedAssignedTargets?.map((target) => target.targetUuid) || []
      );
    } catch (err) {}
  }, [assignedTargetList, setSortedTargetIds]);

  useEffect(() => {
    if (usersResult?.data) {
      setUserList(usersResult?.data);
    }
  }, [usersResult?.data]);

  useEffect(() => {
    if (rolesResult?.data) {
      setRoleList(rolesResult?.data);
    }
  }, [rolesResult?.data]);

  useEffect(() => {
    if (customFieldsResult?.data) {
      setCustomFieldsList(customFieldsResult.data);

      if (customFieldsResult.data.length) {
        setUserCriteria(
          initialUserCriteria
            .concat(
              customFieldsResult.data.map((customField: CustomField) => ({
                id: `${customField.name}|user_custom_field`,
                label: customField.name,
              }))
            )
            .sort((a, b) =>
              a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            )
        );
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [customFieldsResult.data]);

  useEffect(() => {
    if (resizedHeight && emptyContainer.current) {
      setTimeout(
        () =>
          // @ts-ignore
          (emptyContainer.current.style.marginBottom = `${
            resizedHeight + 10
          }px`),
        100
      );
    }
  }, [resizedHeight]);

  function refreshAssignedTargets() {
    assignedTargetsResult.refetch();
  }

  function cancelAndCloseHandler() {
    setTargetInEdit(null);
    closeDrawerTargetAssignments();
  }

  return {
    assignedTargetList,
    cancelAndCloseHandler,
    customFieldsList,
    emptyContainer,
    refreshAssignedTargets,
    resizeElement,
    roleList,
    setResizeElement,
    setSortedTargetIds,
    setTargetInEdit,
    setTargetInEditIsUnassigned,
    sortedTargetIds,
    targetInEdit,
    targetInEditIsUnassigned,
    userCriteria,
    userList,
  };
}

export function useChipList(footerTop: number | undefined) {
  const { close, elementIsOpen, open } = useIsOpen();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { resizeElement, setResizeElement, resizedHeight } =
    useResize<HTMLUListElement>();
  const [placement, setPlacement] =
    useState<PopperPlacementType>('bottom-start');

  useEffect(
    /* istanbul ignore next */
    () => {
      const rect = resizeElement?.getBoundingClientRect();
      if (rect && footerTop) {
        if (rect.bottom > footerTop) {
          setPlacement('top-start');
          return;
        }
      }
    },
    [footerTop, resizeElement, resizedHeight]
  );

  return {
    anchorRef,
    close,
    elementIsOpen,
    open,
    placement,
    setResizeElement,
  };
}
