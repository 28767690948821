import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import CommonErrorComponent from 'components/CommonErrorComponent';
import ButtonPrimary from 'components/ButtonPrimary';
import { styled } from '@mui/system';

const Main = styled('main')(({ theme }) => ({
  width: 'auto',
  minHeight: 'fit-content',
  marginTop: theme.spacing(6),
  padding: theme.spacing(4, 6),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  display: 'block', // needed for IE11 compatibility
}));

function NotFoundErrorPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <Box width={1} textAlign="center">
      <Main role="main">
        <CommonErrorComponent
          errorImageAlt="pageNotFound.images.altPageNotFound"
          errorTitle="pageNotFound.title"
          errorDescription="pageNotFound.description"
        />
        <ButtonPrimary variant="contained" {...{ component: Link, to: '/' }}>
          {t('home.title')}
        </ButtonPrimary>
      </Main>
    </Box>
  );
}

export default NotFoundErrorPage;
