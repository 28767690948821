import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import CheckIcon from '@mui/icons-material/Check';
import { buttonGroupClasses } from '@mui/material';
import isEqual from 'lodash/isEqual';

import GoalSection from './components/GoalSection';
import StyledTooltip from 'components/CommonTooltip';
import {
  GoalsSection,
  headerIconsSx,
  StyledIconButton,
  RedResetButton,
  StyledDivider,
  GoalName,
} from './styles';
import { UseTargetPageResult } from './hooks';
import WordsPhrasesGoal from './components/WordsPhrasesGoal';
import ReuseGoal from './components/ReuseGoal';
import { WORDS_AND_PHRASES_GOAL_NAME } from './constants';
import WarningIcon from 'components/WarningIcon';
import ButtonPrimary from 'components/ButtonPrimary';
import InformationOutlinedRoundedIcon from 'icons/InformationOutlinedRoundedIcon';
import WarningText from 'components/WarningText/WarningText';
import { StyledButtonGroup } from 'components/CommonDrawer';
import EditTargetWithComment from './EditTargetWithComment';
import { green } from '@mui/material/colors';
import useUnsavedChangesOnCloseWarning from 'hooks/useUnsavedChangesOnCloseWarning';
import CommonLockIconButton from 'components/CommonLockIconButton/CommonLockIconButton';
import EditContextsDrawer from './components/EditContextsDrawer';
export function TargetPageOldLayout({
  actions,
  buttonsDimensions,
  commentData,
  configuredGoals,
  dialogWithLSReloadControl,
  dialogWithLSReloadIsActive,
  expandedGoals,
  getGoalConfig,
  headerDimensions,
  languageDisplayName,
  languageIdShort,
  openContextDrawerFor,
  setOpenContextDrawerFor,
  privileges: { editRights },
  reset,
  save,
  setExpandedGoals,
  target,
  targetResult,
  guidelinesWithWarning,
}: UseTargetPageResult) {
  const { t } = useTranslation();

  const [setIsModified] = useUnsavedChangesOnCloseWarning();

  const [isSaved, setIsSaved] = useState<boolean>(false);

  const isModified =
    editRights && target && targetResult.data
      ? !isEqual(
          {
            ...target,
            // comment comes always as null because it can be set only for the current action
            // set it as null only if comment wasn't already set
            ...(!target?.comment && { comment: null }),
          },
          targetResult.data
        )
      : false;

  useEffect(() => {
    if (isModified) {
      setIsSaved(false);
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [isModified, setIsSaved, setIsModified]);

  const height =
    window.innerHeight -
    buttonsDimensions.resizedHeight -
    headerDimensions.resizedHeight -
    // TODO: find a way to calculate this number
    // this number is needed as not all element dimensions (margins, borders and so on) are taken by JS
    210;

  if (targetResult.error) {
    return <p>{targetResult.error.message}</p>;
  }
  return (
    <>
      {openContextDrawerFor && target && (
        <EditContextsDrawer
          close={() => setOpenContextDrawerFor(undefined)}
          disabled={!editRights}
          openFor={openContextDrawerFor}
          setOpenFor={setOpenContextDrawerFor}
          setContextConfig={actions.setContextConfig}
        />
      )}
      <Grid
        container
        ref={buttonsDimensions.setResizeElement}
        sx={(theme) => ({
          paddingBottom: theme.spacing(2),
        })}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection="row"
          flexWrap="nowrap"
          aria-label={`${target?.displayName} ${t('capture.a11y.targetInfo')}`}
        >
          <Grid
            sx={(theme) => ({
              display: 'inline-flex',
              paddingTop: theme.spacing(0.4),
            })}
            alignItems="flex-start"
          >
            <LanguageIcon sx={headerIconsSx} />
            <Typography
              variant="body2"
              sx={(theme) => ({
                minWidth: theme.spacing(35),
                marginRight: theme.spacing(3),
                paddingTop: theme.spacing(0.4),
              })}
              lang={languageIdShort}
            >
              {languageDisplayName}
            </Typography>

            {target?.description && <InformationOutlinedRoundedIcon />}
            <Typography
              sx={(theme) => ({
                maxWidth: theme.spacing(74),
              })}
              variant="body2"
              lang={languageIdShort}
            >
              {target?.description}
            </Typography>
          </Grid>
          <Grid display="flex" sx={{ height: '100%' }} alignItems="center">
            <Grid
              display="flex"
              justifyContent="flex-end"
              flexDirection="column"
            >
              {editRights ? (
                <>
                  <Grid
                    display="inline-flex"
                    justifyContent="flex-end"
                    flexDirection="row"
                  >
                    <RedResetButton
                      onClick={reset}
                      variant="outlined"
                      disabled={!isModified}
                      color="error"
                    >
                      <>{t('capture.actions.reset')}</>
                    </RedResetButton>
                    <StyledButtonGroup
                      variant="contained"
                      sx={(theme) => ({
                        [`&.${buttonGroupClasses.root} .${buttonGroupClasses.grouped}:not(:last-of-type)`]:
                          {
                            borderRight: 'none',
                            borderColor: 'unset',
                            marginRight: theme.spacing(1 / 5),
                          },
                      })}
                    >
                      <ButtonPrimary
                        className="save"
                        onClick={async () => {
                          if (dialogWithLSReloadIsActive) {
                            dialogWithLSReloadControl.open();
                          }

                          await save();
                          setIsSaved(true);
                        }}
                        variant="contained"
                        disabled={!isModified}
                        {...(isSaved && {
                          sx: () => ({
                            '&:disabled.save': {
                              backgroundColor: green['A700'],
                              cursor: 'not-allowed',
                              pointerEvents: 'auto',
                              '&:hover, &:focus, &:focus-within': {
                                backgroundColor: green['A700'],
                              },
                            },
                          }),
                        })}
                      >
                        <>
                          <Collapse
                            in={isSaved}
                            orientation="horizontal"
                            timeout={500}
                          >
                            <CheckIcon fontSize="small" />
                          </Collapse>
                          {isSaved
                            ? t('capture.actions.saved')
                            : t('capture.actions.save')}
                        </>
                      </ButtonPrimary>
                      <EditTargetWithComment
                        commentData={commentData}
                        disabled={!isModified}
                        submitAction={async () => {
                          if (dialogWithLSReloadIsActive) {
                            dialogWithLSReloadControl.open();
                          }

                          await save();
                          setIsSaved(true);
                        }}
                      />
                    </StyledButtonGroup>
                  </Grid>
                  {isModified && (
                    <Grid
                      container
                      flexDirection="row"
                      justifyContent="flex-end"
                      flexWrap="nowrap"
                      sx={(theme) => ({
                        marginTop: theme.spacing(1),
                      })}
                    >
                      <WarningIcon />
                      <WarningText
                        sx={(theme) => ({
                          lineHeight: theme.typography.pxToRem(23),
                        })}
                      >
                        {t('capture.descriptions.unsavedChangesWarning')}
                      </WarningText>
                    </Grid>
                  )}
                </>
              ) : (
                <CommonLockIconButton
                  tooltipText={t('reuse.tooltips.readonly')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid>
        <GoalsSection
          style={{
            height: `${height}px`,
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            scrollbarWidth: 'thin',
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignContent="flex-start"
          >
            <GoalName component="h2">{t('capture.titles.goals')}</GoalName>
            <StyledTooltip
              placement="bottom-end"
              title={
                expandedGoals
                  ? t('capture.actions.tooltips.collapseAll')
                  : t('capture.actions.tooltips.expandAll')
              }
            >
              <StyledIconButton
                className="expand-all"
                onClick={async () => {
                  setExpandedGoals(!expandedGoals);
                }}
                aria-label={
                  expandedGoals
                    ? t('capture.actions.tooltips.collapseAll')
                    : t('capture.actions.tooltips.expandAll')
                }
              >
                {!expandedGoals && <UnfoldMoreIcon color="primary" />}
                {expandedGoals && <UnfoldLessIcon color="primary" />}
              </StyledIconButton>
            </StyledTooltip>
          </Grid>
          {target &&
            !!configuredGoals.length &&
            configuredGoals.map((goal) => {
              if (
                goal.identifier.toLowerCase() === WORDS_AND_PHRASES_GOAL_NAME
              ) {
                return (
                  <WordsPhrasesGoal
                    key={goal.identifier}
                    expandedGoals={expandedGoals}
                    goal={goal}
                    goalConfig={getGoalConfig(goal.identifier)}
                    languageIdShort={languageIdShort}
                    targetActions={actions}
                    target={target}
                    guidelinesWithWarning={guidelinesWithWarning}
                    warningTranslationString="capture.tooltips.noTerminologyForGoalWarning"
                    modified={isModified}
                    readOnly={!editRights}
                  />
                );
              }
              if (goal.identifier.toLowerCase() === 'reuse') {
                return (
                  <ReuseGoal
                    key={goal.identifier}
                    expandedGoals={expandedGoals}
                    goal={goal}
                    goalConfig={getGoalConfig(goal.identifier)}
                    languageIdShort={languageIdShort}
                    targetActions={actions}
                    target={target}
                    guidelinesWithWarning={guidelinesWithWarning}
                    modified={isModified}
                    readOnly={!editRights}
                  />
                );
              }

              return (
                <GoalSection
                  expandedGoals={expandedGoals}
                  goal={goal}
                  goalConfig={getGoalConfig(goal.identifier)}
                  guidelinesWithWarning={guidelinesWithWarning}
                  key={goal.identifier}
                  modified={isModified}
                  readOnly={!editRights}
                  target={target}
                  targetActions={actions}
                  warningTranslationString="capture.tooltips.noTerminologyForGuidelineWarning"
                  languageIdShort={languageIdShort}
                />
              );
            })}
        </GoalsSection>
      </Grid>
    </>
  );
}
