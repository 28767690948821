import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { LinkProps } from 'react-router-dom';

export const StyledListItemButton = styled(ListItemButton)<
  LinkProps & { isClassicView: boolean }
>(({ theme }) => ({
  marginBottom: theme.spacing(1 / 2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '& .MuiSvgIcon-root': {
    opacity: 0,
  },
  '&:hover, &:focus': {
    transition: '0.3s',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      opacity: 1,
    },
  },
  '&.Mui-selected': {
    '&.MuiListItemButton-root': {
      backgroundColor: theme.palette.primary.light,
    },
    '& *': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
  },
  '&.selectedParent': {
    '& .MuiListItemButton-root': {
      backgroundColor: theme.palette.primary.light,
    },
    '& *': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiTypography-body1': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  '.MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(21.34),
  },
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '.MuiListItemText-primary': {
    fontSize: theme.typography.pxToRem(14),
    '&.MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));
