export const COPY_TARGET_PATH = 'targets/:id?displayName';
export const TARGETS_RECENT_ACTIVITIES_PATH = 'targets/changes';
export const MAX_TARGET_NAME_LENGTH = 45;

export const TARGETS_RECENT_ACTIVITIES_QUERY_KEY = 'targets recent activities';

export const MAX_TARGET_EVENT_COMMENT_LENGTH = 200;

export const TARGET_EVENT_PER_PAGE = 50;

export const TARGETS_EDIT_PRIVILEGE = 'LinguisticConfiguration.editTargets';
export const TARGETS_READ_PRIVILEGE = 'LinguisticConfiguration.viewTargets';
