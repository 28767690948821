import React from 'react';
import Switch from '@mui/material/Switch';

import ListIcon from '@mui/icons-material/List';
import { useTranslation } from 'react-i18next';

import StyledTooltip from 'components/CommonTooltip/CommonTooltip';
import {
  ContextsFilterType,
  Goal,
  Guideline,
  GuidelineContext,
  GuidelineValue,
} from '../../types';
import { StyledIconButton, StyledBlockOutlined } from './styles';
import { TargetActions } from '../GoalSection';

export interface ContextualSettingsProps {
  contextsExist: boolean;
  goal: Goal;
  guideline: Guideline;
  guidelineConfig?: GuidelineValue;
  guidelineIsActive: boolean;
  openWarningModal: () => void;
  performOpenEditContextsDrawer: () => void;
  presetWarningModalShouldAppear: boolean;
  readOnly: boolean;
  setWarningModalOKCallback: (callback: () => void) => void;
  targetActions: TargetActions;
}

export function ContextualSettings({
  contextsExist,
  goal,
  guideline,
  guidelineConfig,
  guidelineIsActive,
  openWarningModal,
  performOpenEditContextsDrawer,
  presetWarningModalShouldAppear,
  readOnly,
  setWarningModalOKCallback,
  targetActions,
}: ContextualSettingsProps): JSX.Element {
  const { t } = useTranslation();

  return contextsExist && guideline.contextuallyConfigurable ? (
    <>
      <StyledTooltip
        title={readOnly ? '' : t('capture.actions.tooltips.toggleContexts')}
      >
        <span
          {...((!guidelineIsActive || readOnly) && {
            style: {
              cursor: 'not-allowed',
            },
          })}
        >
          <Switch
            checked={guidelineConfig?.contextsActive}
            disabled={!guidelineIsActive || readOnly}
            color="primary"
            name="context config"
            size="small"
            onChange={(_ev, active) => {
              function onChangeHandler() {
                if (active) {
                  performOpenEditContextsDrawer();
                }
                targetActions.setContextConfig(goal, guideline.identifier, {
                  contexts: guidelineConfig?.contexts as GuidelineContext[],
                  active,
                  contextsFilterType:
                    guidelineConfig?.contextsFilterType as ContextsFilterType,
                });
              }

              if (presetWarningModalShouldAppear) {
                setWarningModalOKCallback(() => onChangeHandler);
                openWarningModal();
                return;
              }

              onChangeHandler();
            }}
            inputProps={{
              'aria-label': t('capture.labels.toggleContexts'),
            }}
          />
        </span>
      </StyledTooltip>

      <StyledTooltip
        title={t(
          readOnly
            ? 'capture.a11y.readOnlyContextsIcon'
            : 'capture.actions.tooltips.editContexts'
        )}
      >
        <span
          {...(!(guidelineConfig?.contextsActive && guidelineIsActive) && {
            style: {
              cursor: 'not-allowed',
            },
          })}
        >
          <StyledIconButton
            disabled={!(guidelineConfig?.contextsActive && guidelineIsActive)}
            aria-label={t(
              readOnly
                ? 'capture.a11y.readOnlyContextsIcon'
                : 'capture.actions.tooltips.editContexts'
            )}
            disableFocusRipple={true}
            color={guidelineConfig?.contextsActive ? 'primary' : 'secondary'}
            onClick={performOpenEditContextsDrawer}
          >
            <ListIcon />
          </StyledIconButton>
        </span>
      </StyledTooltip>
    </>
  ) : (
    <StyledTooltip
      title={<> {t('capture.actions.tooltips.noContextualSettings')} </>}
    >
      <StyledBlockOutlined />
    </StyledTooltip>
  );
}

export default ContextualSettings;
