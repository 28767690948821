import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { StyledListItemButton } from './styles';

export const ListItemButtonComponent = forwardRef(
  (
    {
      children,
      className,
      isClassicView,
      to,
      href,
    }: React.ComponentProps<typeof StyledListItemButton>,
    ref: React.Ref<HTMLAnchorElement>
  ) =>
    isClassicView ? (
      <a ref={ref} href={href} className={className}>
        {children}
      </a>
    ) : (
      <NavLink to={to} className={className} exact innerRef={ref}>
        {children}
      </NavLink>
    )
);
