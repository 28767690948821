import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CommonTooltip from 'components/CommonTooltip/CommonTooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AndChip,
  CustomFieldChip,
  DocumentReferenceChip,
  LanguageDetectionChip,
} from './styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { blueGrey, grey } from '@mui/material/colors';
import { DocumentCriteriaCardSectionProps } from './types';

const BatchCheckingHeader = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  color: grey[600],
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: blueGrey[300],
  marginRight: theme.spacing(-1),
  padding: theme.spacing(0.5),
  width: theme.spacing(5.6),
  height: theme.spacing(5.6),
  borderRadius: theme.spacing(5),
  '&:hover': {
    backgroundColor: blueGrey[50],
  },
}));

const ChipContainer = styled('span')(() => ({
  display: 'inline-flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: '3px',
  backgroundColor: grey[50],
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(-1),
  padding: theme.spacing(1.4, 2),
  border: '1px solid',
  borderColor: theme.palette.custom.aliceBlue,
}));

export default function DocumentCriteriaCardSection({
  target,
}: DocumentCriteriaCardSectionProps): JSX.Element {
  const { t } = useTranslation();

  const documentCustomFieldValuesAvailable =
    target.documentCustomFieldCriteria.length > 0;
  const documentReferenceCriteriaAvailable =
    target.documentReferenceCriteria.length > 0;

  const documentReferenceOrLanguageCriteriaAvailable =
    documentCustomFieldValuesAvailable || documentReferenceCriteriaAvailable;

  const documentCustomFieldsOrLanguageCriteriaAvailable =
    documentCustomFieldValuesAvailable || target.enableLanguageDetection;
  return (
    <StyledBox>
      <Grid container justifyContent="space-between">
        <BatchCheckingHeader>
          {t('capture.labels.documentCriteria')}
        </BatchCheckingHeader>
        <CommonTooltip
          type="yellow"
          placement="bottom-start"
          title={<>{t('capture.tooltips.batchCheckCriteriaExplanation')}</>}
        >
          <StyledInfoOutlinedIcon />
        </CommonTooltip>
      </Grid>
      {target.documentCustomFieldCriteria?.length > 1 ||
      documentReferenceOrLanguageCriteriaAvailable
        ? target.documentCustomFieldCriteria.map((criterion, index) => {
            return (
              <ChipContainer key={target.targetName + '-' + criterion.name}>
                <CustomFieldChip
                  key={target.targetName + '-' + criterion.name}
                  labelText={
                    criterion.name + ' (' + criterion.criteria?.length + ')'
                  }
                />
                {!!target.documentCustomFieldCriteria[index + 1] && (
                  <AndChip
                    key={target.targetName + '-' + criterion.name + '-and'}
                    label={t('capture.labels.andChip')}
                  />
                )}
              </ChipContainer>
            );
          })
        : target.documentCustomFieldCriteria?.length === 1 &&
          target.documentCustomFieldCriteria[0].criteria?.map(
            (criterion, index) => (
              <ChipContainer key={target.targetName + '-' + criterion}>
                <CustomFieldChip
                  key={target.targetName + '-' + criterion}
                  labelText={criterion}
                />
                {(documentReferenceCriteriaAvailable ||
                  target.enableLanguageDetection) &&
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore: Object is possibly 'null'.
                  !target.documentCustomFieldCriteria[0]?.criteria[
                    index + 1
                  ] && (
                    <AndChip
                      key={target.targetName + '-' + criterion + '-and'}
                      label={t('capture.labels.andChip')}
                    />
                  )}
              </ChipContainer>
            )
          )}

      {documentCustomFieldsOrLanguageCriteriaAvailable &&
      documentReferenceCriteriaAvailable ? (
        <ChipContainer>
          <DocumentReferenceChip
            key={`${target.targetName}-Document Reference`}
            labelText={
              t('capture.labels.documentReference') +
              ' (' +
              target.documentReferenceCriteria.length +
              ')'
            }
          />
          {target.enableLanguageDetection && (
            <AndChip
              key={target.targetName + '-docreference'}
              label={t('capture.labels.andChip')}
            />
          )}
        </ChipContainer>
      ) : (
        target.documentReferenceCriteria.map((reference) => (
          <DocumentReferenceChip
            key={target.targetName + reference}
            labelText={reference}
          />
        ))
      )}

      {target.enableLanguageDetection && (
        <>
          <LanguageDetectionChip
            key="language-detection-chip"
            labelText={t('capture.labels.languageDetectionChip')}
          />
        </>
      )}
    </StyledBox>
  );
}
