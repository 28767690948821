import { useContext, useState } from 'react';
import PrivilegesContext from 'contexts/PrivilegesContext';
import { TARGETS_EDIT_PRIVILEGE, TARGETS_READ_PRIVILEGE } from '../constants';

export interface TargetsUserRights {
  editRights: boolean;
  readRights: boolean;
}

export default function usePrivileges() {
  const privileges = useContext(PrivilegesContext);
  const [userRights] = useState<TargetsUserRights>(() => ({
    editRights: privileges.includes(TARGETS_EDIT_PRIVILEGE),
    readRights: privileges.includes(TARGETS_READ_PRIVILEGE),
  }));

  return userRights;
}
