import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';

import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';

import UsersTable, { HeadCellType } from './components/UsersTable';
import TablePaginationCustom from 'components/TablePaginationCustom';
import CurrentUserContext from 'contexts/CurrentUserContext';
import SelectionLimitAlertDialog from './components/SelectionLimitAlertDialog';
import {
  LIMIT_COUNT_SELECTED_ITEMS,
  LS_USERS_CURRENT_PAGE_KEY,
  LS_USERS_VISIBLE_COLUMNS_KEY,
} from './constants';

import { useTranslation } from 'react-i18next';
import UserContext from 'contexts/UserContext';
import {
  calculateNewSelection,
  generateColumns,
  getAvailableUsers,
  getInitialUsersFilter,
  useGetUsers,
  usersFilterReducer,
} from './utils';
import { SortOrder, User } from 'sections/Users/types';
import { UsersTableFilters } from './components/UsersTableFilters';
import { WithErrorHandling } from './components/WithErrorHandling';
import { StyledPageContentWrapper, StyledTableContainer } from './styles';

function UsersIndexPage(): ReactElement {
  const { t } = useTranslation();
  const { id: currentUserId } = useContext(UserContext) || {};
  const currentUser = useContext(CurrentUserContext);
  const [usersFilterState, dispatch] = useReducer(
    usersFilterReducer,
    getInitialUsersFilter()
  );

  const { data, isLoading, isPreviousData, refetch } =
    useGetUsers(usersFilterState);
  const [selected, setSelected] = useState<ReadonlyArray<User['id']>>([]);
  const [columns, setColumns] = useState<HeadCellType[]>(
    generateColumns(currentUser?.customFields)
  );

  const handleSelection = React.useCallback((id: string, checked: boolean) => {
    setSelected((selectedUsers) =>
      checked
        ? [...selectedUsers, id]
        : selectedUsers.filter((user) => user !== id)
    );
  }, []);

  const { totalItems = 0, totalPages = 0 } = {
    ...(data?.headers && data.headers),
  };
  const itemsCount = data?.data?.length ?? 0;
  const users = data?.data;
  const selectedOnCurrentPage = useMemo(
    () =>
      users?.map(({ id }) => id).filter((id) => selected.includes(id)) ?? [],
    [selected, users]
  );

  const onAfterChange = React.useCallback(() => {
    setSelected([]);
    refetch();
  }, [refetch]);

  const onSelectAll = React.useCallback(
    (isSelectAll: boolean) => {
      setSelected(
        calculateNewSelection(
          selected,
          getAvailableUsers(users, currentUserId),
          isSelectAll
        )
      );
    },
    [currentUserId, users, selected]
  );

  const onChangeOrder = useCallback((by: keyof User, direction: SortOrder) => {
    localStorage.setItem('Users-Sorting', `${by}|${direction}`);
    dispatch({
      type: 'setOrder',
      payload: { order: direction, orderBy: by },
    });
  }, []);

  const onChangeFilteredRoles = useCallback(
    (newFilteredRoles: User['roles']) => {
      localStorage.setItem(
        'Users-RolesFilter',
        JSON.stringify(
          newFilteredRoles.map((role) => {
            return {
              id: role.id,
              name: role.name,
              default: role.default,
            };
          })
        )
      );
      dispatch({
        type: 'setFilteredRoles',
        payload: newFilteredRoles,
      });
    },
    []
  );

  const onSetColumns = (columns: React.SetStateAction<HeadCellType[]>) => {
    setColumns(columns);

    const localeStorageVisibleColumns = localStorage.getItem(
      LS_USERS_VISIBLE_COLUMNS_KEY
    );

    let visibleColumns = [];
    try {
      visibleColumns =
        localeStorageVisibleColumns !== null
          ? JSON.parse(localeStorageVisibleColumns)
          : [];
    } catch (error) {
      console.error('An error occurred while parsing JSON:', error);
    }

    dispatch({
      type: 'setCustomFields',
      payload: visibleColumns
        .filter((vc: string) => vc.startsWith('customFields'))
        .map((vc: string) => vc.split('.')[1]),
    });
  };

  return (
    <WithErrorHandling>
      <>
        <GlobalStyles
          styles={{
            body: {
              margin: 0,
              height: '100%',
              overflow: 'hidden',
            },
          }}
        />
        <SelectionLimitAlertDialog selectionCount={selected.length} />
        <StyledPageContentWrapper pageTitle={t('users.title')}>
          <StyledTableContainer>
            <Box p={6} aria-live="polite">
              <UsersTableFilters
                usersCount={totalItems}
                itemsCount={itemsCount}
                itemsSelected={selected.length}
                columns={columns}
                setColumns={onSetColumns}
                usersFilterState={usersFilterState}
                dispatch={dispatch}
              />
              <UsersTable
                rows={users}
                columns={columns}
                isBusy={isLoading || isPreviousData}
                onSelect={handleSelection}
                selected={selectedOnCurrentPage}
                onChangeOrder={onChangeOrder}
                orderDirection={usersFilterState.order}
                orderBy={usersFilterState.orderBy}
                onAfterChange={onAfterChange}
                filteredRoles={usersFilterState.filteredRoles}
                onChangeFilteredRoles={onChangeFilteredRoles}
                numRemainingCountForSelection={
                  LIMIT_COUNT_SELECTED_ITEMS - selected.length
                }
                onSelectAll={onSelectAll}
              />
              {data?.data.length !== 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  minWidth={'fit-content'}
                  pt={5}
                >
                  <Box>
                    <TablePaginationCustom
                      page={
                        localStorage.getItem(LS_USERS_CURRENT_PAGE_KEY)
                          ? Number(
                              localStorage.getItem(LS_USERS_CURRENT_PAGE_KEY)
                            )
                          : usersFilterState.page
                      }
                      totalPages={totalPages}
                      onChangePage={(newPage) => {
                        localStorage.setItem(
                          LS_USERS_CURRENT_PAGE_KEY,
                          newPage.toString()
                        );
                        dispatch({ type: 'setPage', payload: newPage });
                      }}
                      localStorageKey={LS_USERS_CURRENT_PAGE_KEY}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </StyledTableContainer>
        </StyledPageContentWrapper>
      </>
    </WithErrorHandling>
  );
}

export default UsersIndexPage;
