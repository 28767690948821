import * as React from 'react';
import ErrorNotificationDialog from 'sections/Users/components/ErrorNotificationDialog';
import { ApiError } from 'errors/ApiError';
import InternalServerErrorComponent from 'pages/InternalServerErrorComponent';
import NotFoundErrorPage from 'pages/NotFoundErrorPage';
import { apiErrorToNotification } from 'errors/utils';

export const GetUsersError = ({ error }: { error: ApiError | Error }) => (
  <>
    <ErrorNotificationDialog {...apiErrorToNotification(error)} />
    {error instanceof ApiError &&
    (error.status === 403 || error.status === 404) ? (
      <NotFoundErrorPage />
    ) : (
      <InternalServerErrorComponent />
    )}
  </>
);
