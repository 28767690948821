import React, { ReactNode, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { pink } from '@mui/material/colors';

import { ContentBox, FooterBox, HeaderBox } from './components';
import {
  BackIconButton,
  StyledLink,
  StyledBackIconButtonTooltip,
} from './styles';
import { UseResizeResult } from 'hooks/useResize';

export interface PageLayoutProps {
  backLink: string;
  children: React.ReactElement | React.ReactElement[];
  focusHeading?: boolean;
  headerDimensions?: UseResizeResult<HTMLDivElement>;
  heading: string | undefined;
  rightSideHeaderComponent?: ReactNode;
  backIconText: string;
  lang?: string;
}

export default function DetailsPageLayout({
  backLink,
  children,
  focusHeading = false,
  heading = '',
  headerDimensions,
  rightSideHeaderComponent,
  backIconText,
  lang,
}: PageLayoutProps): JSX.Element {
  const headingRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusHeading) {
      headingRef.current?.focus();
    }
  }, [focusHeading]);

  return (
    <section>
      <HeaderBox component="div" ref={headerDimensions?.setResizeElement}>
        <Grid container>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Grid
              sx={{ display: 'inline-flex' }}
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
            >
              <StyledBackIconButtonTooltip
                title={backIconText}
                placement="bottom-start"
              >
                <StyledLink tabIndex={-1} to={backLink}>
                  <BackIconButton
                    aria-label={backIconText}
                    size="large"
                    disableRipple
                  >
                    <ArrowBackIcon color="primary" />
                  </BackIconButton>
                </StyledLink>
              </StyledBackIconButtonTooltip>
              <Typography
                variant="h1"
                ref={headingRef}
                tabIndex={-1}
                sx={(theme) => ({
                  color: pink['A700'],
                  fontSize: theme.typography.pxToRem(24),
                  fontWeight: theme.typography.fontWeightRegular,
                  margin: 0,
                })}
                {...(lang && { lang })}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid
              sx={(theme) => ({
                marginRight: theme.spacing(0.4),
              })}
            >
              {rightSideHeaderComponent}
            </Grid>
          </Grid>
        </Grid>
      </HeaderBox>
      <ContentBox>{children}</ContentBox>
      <FooterBox />
    </section>
  );
}
