import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { useFetch } from 'utils/fetch';
import { USER_API_PATH } from 'constants/constants';
import { USER_AND_ROLES_EDIT_USER } from 'constants/privileges';
import PrivilegesContext from 'contexts/PrivilegesContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import { useErrorNotificationDialog } from 'sections/Users/components/ErrorNotificationDialog';
import { apiErrorToNotification } from 'errors/utils';
import { SUPER_ADMINISTRATOR } from '../../../UsersIndexPage/constants';
import { Role, User } from '../../../../types';
import { ApiError } from 'errors/ApiError';
import { useGetUserQuery, useRolesQuery } from './queries';

interface RoleInfoResult {
  errorData: Error | null;
  userData?: User;
  licenseType?: User['licenseType'];
  username?: string;
  isCurrentUserSuperAdmin?: boolean;
  canEditUsers: boolean;
  handleChangeRoles: (role: Role) => Promise<void>;
  rolesData?: { data: ReadonlyArray<Role> };
  roles?: ReadonlyArray<Role>;
}

export function useRoleInfo(userId: User['id']): RoleInfoResult {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userPrivileges = useContext(PrivilegesContext);
  const selfData = useContext(CurrentUserContext);
  const history = useHistory();
  const { open } = useErrorNotificationDialog();
  const http = useFetch();
  const userRoleMutation = useMutation({
    mutationFn: (roles: ReadonlyArray<Role>) =>
      http.put<User, { roles: ReadonlyArray<Role> }>(
        `${USER_API_PATH}/${userId}`,
        { roles }
      ),
  });

  const canEditUsers = userPrivileges.includes(USER_AND_ROLES_EDIT_USER);
  const isCurrentUserSelected = selfData?.id === userId;

  const { data: rolesData } = useRolesQuery();

  const { data, error: errorData } = useGetUserQuery(
    userId,
    isCurrentUserSelected
  );

  const userData = isCurrentUserSelected ? selfData : data?.data;

  const { username, roles, licenseType } = userData ?? {};

  const { roles: currentUserRoles } = selfData ?? {};

  const isCurrentUserSuperAdmin = currentUserRoles?.some(
    ({ name }) => name === SUPER_ADMINISTRATOR
  );

  const handleChangeRoles = async (role: Role) => {
    const isRoleAssigned = roles?.map(({ id }) => id).includes(role.id);
    const updatedRoles = isRoleAssigned
      ? roles?.filter(({ id }) => id !== role.id) ?? []
      : [...(roles || []), role];

    try {
      const updatedUser = await userRoleMutation.mutateAsync(updatedRoles);

      enqueueSnackbar(
        isRoleAssigned
          ? t('user.notification.roleRemovedSuccessfully')
          : t('user.notification.roleAddedSuccessfully')
      );

      if (isCurrentUserSelected) {
        queryClient.refetchQueries({ queryKey: ['userPrivileges'] });
        queryClient.refetchQueries({ queryKey: ['currentUser'] });
      }

      queryClient.setQueryData(['user', userId], updatedUser);
    } catch (error) {
      open({
        ...apiErrorToNotification(error),
        onClose: () => {
          if (error instanceof ApiError) {
            if (error.status === 403) {
              window.location.reload();
            } else if (error.status === 404) {
              history.replace({ pathname: '/user-management/users' });
            }
          }
        },
      });
    }
  };

  return {
    errorData,
    userData,
    username,
    licenseType,
    roles,
    rolesData,
    isCurrentUserSuperAdmin,
    canEditUsers,
    handleChangeRoles,
  };
}
