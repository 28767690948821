import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { DataForCheckboxes, Options, User } from './types';
import { NewAddedText } from 'sections/Targets/pages/TargetPage/types';
import { CustomField, TargetAssignment } from 'sections/Targets/types';

export function getCustomFieldIndex(
  data: DataForCheckboxes,
  target: TargetAssignment
): number {
  if (!data.propertyName) {
    return -1;
  }

  const index = (target[data.propertyName] as CustomField[])?.findIndex(
    (element) => element.name === data.customFieldName
  );
  return typeof index !== 'number' ? -1 : index;
}

export function removeEmptyCustomField(
  customFieldIndex: number,
  data: DataForCheckboxes,
  target: TargetAssignment
): CustomField[] {
  if (!data.propertyName) {
    return [];
  }

  if (customFieldIndex === -1) {
    return target[data.propertyName] as CustomField[];
  }

  const customFields = [...(target[data.propertyName] as CustomField[])];
  customFields.splice(customFieldIndex, 1);

  return customFields;
}

export function addAToCustomField({
  customFieldIndex,
  data,
  target,
  all,
  value,
}: {
  customFieldIndex: number;
  data: DataForCheckboxes;
  target: TargetAssignment;
  all: boolean;
  value: string;
}): CustomField[] {
  if (!data.propertyName || !data.optionsFieldName) {
    return [];
  }

  const customField: CustomField =
    customFieldIndex > -1
      ? getCustomField(data, target, customFieldIndex)
      : {
          name: data.customFieldName as string,
          [data.optionsFieldName]: [],
        };
  if (customFieldIndex === -1) {
    customFieldIndex = target[data.propertyName].length;
  }

  const newOptions = all
    ? (data.options || []).map((opt) => opt.id)
    : customField[data.optionsFieldName]?.concat([value]);

  const customFields: CustomField[] = [
    ...(target[data.propertyName] as CustomField[]),
  ];

  customFields[customFieldIndex] = {
    ...customField,
    [data.optionsFieldName]: newOptions,
  } as CustomField;

  return customFields;
}

export function getCustomField(
  data: DataForCheckboxes,
  target: TargetAssignment,
  customFieldIndex?: number
): CustomField {
  return (
    ((typeof data.getPropertyValue === 'function' &&
      data.getPropertyValue(target)) as CustomField) ||
    (typeof customFieldIndex === 'number' &&
      data.propertyName &&
      (target[data.propertyName] as CustomField[])[customFieldIndex])
  );
}

export function removeOptionFromCustomField(
  customFieldIndex: number,
  data: DataForCheckboxes,
  target: TargetAssignment,
  value: string
): CustomField[] {
  if (!data.propertyName) {
    return [];
  }

  if (customFieldIndex === -1 || !data.optionsFieldName) {
    return target[data.propertyName] as CustomField[];
  }

  const originalCustomField: CustomField = getCustomField(
    data,
    target,
    customFieldIndex
  );

  // @ts-ignore
  const options: string[] = [...originalCustomField[data.optionsFieldName]];

  const index = options.findIndex((opt) => opt === value);

  options.splice(index, 1);

  const customField = {
    ...originalCustomField,
    [data.optionsFieldName]: options,
  };

  const customFields: CustomField[] = [
    ...target[data.propertyName],
  ] as CustomField[];
  customFields[customFieldIndex] = customField;

  return customFields;
}

export function handleChangeForCustomFields({
  checked,
  data,
  target,
  setNewTarget,
  value,
  all = false,
}: {
  checked: boolean;
  data: DataForCheckboxes;
  target: TargetAssignment;
  setNewTarget: (target: TargetAssignment) => void;
  all: boolean;
  value: string;
}): void {
  const customFieldIndex = getCustomFieldIndex(data, target);
  if (
    (customFieldIndex === -1 && !checked) ||
    !data.propertyName ||
    !data.optionsFieldName
  ) {
    return;
  }

  if (all && !checked && customFieldIndex > -1) {
    setNewTarget({
      ...target,
      [data.propertyName]: removeEmptyCustomField(
        customFieldIndex,
        data,
        target
      ),
    });
    return;
  } else if (!checked) {
    const originCustomField = getCustomField(data, target, customFieldIndex);

    if (
      !customFieldIndex &&
      originCustomField[data.optionsFieldName]?.length === 1
    ) {
      setNewTarget({
        ...target,
        [data.propertyName]: removeEmptyCustomField(
          customFieldIndex,
          data,
          target
        ),
      });
    } else {
      setNewTarget({
        ...target,
        [data.propertyName]: removeOptionFromCustomField(
          customFieldIndex,
          data,
          target,
          value
        ),
      });
    }
    return;
  }

  setNewTarget({
    ...target,
    [data.propertyName]: addAToCustomField({
      customFieldIndex,
      data,
      target,
      all,
      value,
    }),
  });
}

export function calculateAllChecked(
  data: DataForCheckboxes,
  target: TargetAssignment
): boolean {
  if (data.allPropertyName && target[data.allPropertyName]) {
    return true;
  }

  const propertyObj = data.propertyName && target[data.propertyName];

  const targetAssignmentSelection =
    getTargetAssignmentCustomFieldPropertySelection(data, target) ??
    propertyObj;

  return isDisplayedEqualToTargetAssignmentSelection(
    targetAssignmentSelection as string[],
    data.options
  );
}

export function calculateIndeterminate(
  checked: boolean,
  data: DataForCheckboxes,
  target: TargetAssignment,
  newAddedTextList?: NewAddedText[]
): boolean {
  const propertyObj = data.propertyName && target[data.propertyName];

  const targetAssignmentSelection =
    getTargetAssignmentCustomFieldPropertySelection(data, target) ??
    propertyObj;

  const anyTextChecked = !!(
    newAddedTextList && newAddedTextList.find((textObj) => textObj.checked)
  );
  const anyChecked =
    anyTextChecked ||
    isAnyTargetAssignmentSelectionInDisplayed(
      targetAssignmentSelection as string[],
      data.options
    );

  const allTextsChecked =
    !newAddedTextList ||
    !newAddedTextList.length ||
    (newAddedTextList &&
      newAddedTextList.reduce(
        (acc, textObj) => acc && textObj.checked,
        true as boolean
      ));
  const allChecked =
    allTextsChecked &&
    isDisplayedEqualToTargetAssignmentSelection(
      targetAssignmentSelection as string[],
      data.options
    );

  return (
    (checked && anyChecked && !allChecked) ||
    (!checked && anyChecked && !allChecked) ||
    false
  );
}

export function getTargetAssignmentCustomFieldPropertySelection(
  data: DataForCheckboxes,
  target: TargetAssignment
): string[] | null {
  return data.customFieldName &&
    data.optionsFieldName &&
    typeof data.getPropertyValue === 'function'
    ? (((data.getPropertyValue(target) || {}) as CustomField)[
        data.optionsFieldName
      ] as string[]) || []
    : null;
}

export function getPropertyValue(
  propertyName: DataForCheckboxes['propertyName'],
  name: string,
  target: TargetAssignment
): CustomField | undefined {
  return propertyName && name
    ? (target[propertyName] as CustomField[]).find(
        (customField: CustomField) => customField.name === name
      )
    : undefined;
}

export function isDisplayedEqualToTargetAssignmentSelection(
  targetAssignmentSelection: string[],
  displayedOptions: Options[]
): boolean {
  if (!targetAssignmentSelection.length || !displayedOptions.length) {
    return false;
  }

  return displayedOptions.reduce(
    (res: boolean, { id }) =>
      res && !!targetAssignmentSelection.find((value) => value === id),
    true
  );
}

export function isAnyTargetAssignmentSelectionInDisplayed(
  targetAssignmentSelection: string[],
  displayedOptions: Options[]
): boolean {
  return displayedOptions.reduce(
    (res: boolean, { id }) =>
      res || !!targetAssignmentSelection.find((value) => value === id),
    false
  );
}

export function areDataForCheckboxesObjectsEqual(
  obj1: DataForCheckboxes,
  obj2: DataForCheckboxes,
  excludedFields?: (keyof DataForCheckboxes)[]
) {
  const obj1Copy = cloneDeep(obj1);
  const obj2Copy = cloneDeep(obj2);

  excludedFields?.forEach((propName) => {
    delete obj1Copy[propName];
    delete obj2Copy[propName];
  });

  return isEqual(obj1Copy, obj2Copy);
}

export function filterByKeyword(
  userList: User[],
  filterKey: string
): Options[] {
  const options = userList.map(
    (user): Options => ({ id: user.uuid, label: user.name })
  );

  return filterKey
    ? options.filter(({ label }) => {
        return label.toLowerCase().includes(filterKey.toLowerCase());
      })
    : options;
}
