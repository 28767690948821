import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import PageContentWrapper from 'components/PageContentWrapper';
import SelectTypeA from '../../../../components/SelectTypeA';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Paper from '@mui/material/Paper';

export const SearchByTextInputStyledClearIcon = styled(SearchIcon)(
  ({ theme }) => ({
    color: theme.palette.grey[600],
  })
);

export const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const StyledPageContentWrapper = styled(PageContentWrapper)(() => ({
  height: 'calc(100vh - 64px)',
  position: 'relative',
}));

export const StyledTableContainer = styled(Paper)(() => ({
  boxShadow: '0px 2px 4px #00000029',
}));

export const StyledSelectTypeA = styled(SelectTypeA)(({ theme }) => ({
  width: theme.spacing(13),
}));

export const ExportIcon = styled(SaveAltIcon)(() => ({
  transform: 'scale(0.889)',
}));
