import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.48),
  lineHeight: theme.typography.pxToRem(36),
  marginBottom: theme.spacing(4),
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(30),
  marginBottom: theme.spacing(6),
}));

export const StyledContent = styled(List)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(30),
  '& li:before': {
    content: '"·"',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.typography.pxToRem(30),
    paddingRight: theme.spacing(1),
  },
}));

export const StyledMainBox = styled(Box)(({ theme }) => ({
  width: 'auto',
  minHeight: 'fit-content',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  padding: theme.spacing(6),
}));
