import React, { ForwardedRef, forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const HeaderBox = forwardRef(
  (props: BoxProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box
        ref={ref}
        component="header"
        display="flex"
        alignItems="center"
        py={4}
        {...props}
        sx={(theme) => ({
          padding: theme.spacing(0.4, 0, 3.4),
        })}
      />
    );
  }
);

export const MainBoxPart = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})<BoxProps & { type?: 'index' | 'details' }>(({ theme, type = 'index' }) => ({
  padding: theme.spacing(0, type === 'index' ? 6 : 4),
  display: 'block', // needed for IE11 compatibility
  overflowX: 'scroll',
}));

export const MainBox = styled('main')(({ theme }) => ({
  minHeight: 'fit-content',
  padding: theme.spacing(6, 0),
  background: theme.palette.background.paper,
  borderRadius: '6px',
  boxShadow: '0px 2px 4px #00000029',
  display: 'block', // needed for IE11 compatibility
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: 'fit-content',
  padding: theme.spacing(4, 0),
  background: theme.palette.background.paper,
  borderRadius: '6px',
  boxShadow: '0px 2px 4px #00000029',
  display: 'block', // needed for IE11 compatibility
}));

export const MainBoxDivider = styled(Box)(({ theme }) => ({
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${theme.palette.custom.lighterGrey}`,
}));

export function FooterBox(props: BoxProps) {
  return (
    <Box
      {...props}
      component="footer"
      sx={(theme) => ({
        paddingBottom: theme.spacing(8),
      })}
    />
  );
}
