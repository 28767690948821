import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import { IndexPageLayout } from 'components/PageLayout';
import { MainBoxPart } from 'components/PageLayout/components';
import BusyIndicator from 'components/BusyIndicator';
import CommonStartPageNoData from 'components/CommonStartPage/CommonStartPageNoData';
import useTitle from 'hooks/useTitle';
import NotFoundErrorPage from 'pages/NotFoundErrorPage';
import usePrivileges from 'sections/Reuse/hooks/usePrivileges';
import DomainTable from './components/DomainTable';
import ButtonPanel from './ButtonPanel';
import { useDeleteDomain } from './hooks';
import { useReuseDomains } from '../../hooks/useReuseDomains';

export default function ReuseIndexPage(): ReactElement {
  const { t } = useTranslation();
  const {
    refreshIndexDomains,
    reuseData,
    reuseDomainsResult,
    sortedDomains,
    sortState,
  } = useReuseDomains();
  const deleteDomainDialogState = useDeleteDomain(refreshIndexDomains);
  const { editRights, readRights } = usePrivileges();

  useTitle(`${t('reuse.newTitle')} - ${t('shared.app.name')}`);

  if (!editRights && !readRights) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <IndexPageLayout
        heading={t('reuse.newTitle')}
        focusHeading
        helpIconText={t(
          editRights
            ? 'reuse.tooltips.reuseHelpIcon'
            : 'reuse.tooltips.reuseHelpIconReadOnly'
        )}
      >
        <MainBoxPart>
          <ButtonPanel
            deleteDomainDialogState={deleteDomainDialogState}
            editRights={editRights}
            refreshIndexDomains={refreshIndexDomains}
            reuseData={reuseData}
            sortedDomains={sortedDomains}
            readRights={readRights}
          />

          {!reuseDomainsResult.data ? (
            <Box pt={20} pb={17}>
              <BusyIndicator />
            </Box>
          ) : (
            <>
              <DomainTable
                sortState={sortState}
                sortedDomains={sortedDomains}
                setDomainForDeleteConfirmation={
                  deleteDomainDialogState.setDomainForDeleteConfirmation
                }
                showIcons={editRights}
              />

              {Array.isArray(reuseDomainsResult?.data) &&
                !sortedDomains.length && (
                  <CommonStartPageNoData
                    title={t('reuse.hints.noDomainFound')}
                    description={t('reuse.hints.createDomain')}
                  />
                )}
            </>
          )}
        </MainBoxPart>
      </IndexPageLayout>
    </>
  );
}
