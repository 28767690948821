import React, { ReactElement, useContext } from 'react';
import PrivilegesContext from 'contexts/PrivilegesContext';
import NavItemComponent from './components/NavigationMenuItem';
import usePages from 'hooks/usePages';

import { List } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListDivider } from './components/ListDivider';
import { ListHeading } from './components/ListHeading';
import { PageDefinitionProps } from './types';

const StyledNavigationMenu = styled(NavigationMenu)(({ theme }) => ({
  '&.MuiList-root.subMenu.level1 .MuiListItemButton-root': {
    paddingLeft: theme.spacing(5),
  },
  '&.MuiList-root.subMenu.level2 .MuiListItemButton-root': {
    paddingLeft: theme.spacing(7),
  },
}));

export interface NavigationMenuProps {
  basePath?: string;
  className?: string;
  level?: number;
  onClick?: () => void;
}

export default function NavigationMenu({
  basePath,
  className,
  level = 0,
  onClick,
}: NavigationMenuProps): ReactElement {
  const userPrivileges = useContext(PrivilegesContext);
  const location = useLocation();
  const { t } = useTranslation();

  const pagesDefinition = usePages();

  const pagesInMainMenu = pagesDefinition.filter(
    ({ mainMenu }) => mainMenu.display // Keep only pages which are desired to be displayed in menu
  );

  return (
    <List
      component={level === 0 ? 'nav' : 'div'}
      className={className}
      disablePadding
      aria-label={
        level === 0
          ? t('shared.components.sideNavigation.a11y.label')
          : undefined
      }
    >
      {pagesInMainMenu
        .filter(
          ({ parent }) =>
            (level === 0 && !parent) ||
            (level > 0 && parent && basePath && basePath === parent)
        )
        .filter(
          // Filter out pages which should not be visible to user
          ({ privileges: itemPrivileges = [] }) =>
            !itemPrivileges.length ||
            itemPrivileges.some((privilege) =>
              userPrivileges.includes(privilege)
            )
        )
        .map((props: PageDefinitionProps) => {
          if (props.type === 'divider') {
            return <ListDivider key={props.privileges?.join()} />;
          }

          if (props.type === 'heading') {
            return <ListHeading title={props.title} key={props.title} />;
          }

          const { routePath } = props;
          // Check if there are children menu items for this item
          const children = pagesInMainMenu.filter(
            ({ parent }) => parent && routePath === parent
          );

          return (
            <Box key={props.title}>
              <NavItemComponent
                {...props}
                path={routePath}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                }}
              />
              {children &&
                children.length > 0 &&
                !props?.mainMenu?.linkToClassicView && (
                  <Collapse
                    in={location.pathname.includes(routePath || '')}
                    timeout="auto"
                    unmountOnExit
                  >
                    <StyledNavigationMenu
                      level={level + 1}
                      basePath={routePath}
                      className={`subMenu level${level + 1}`}
                    />
                  </Collapse>
                )}
            </Box>
          );
        })}
    </List>
  );
}
