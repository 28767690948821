import { TemporalStructureObject } from './types';
import { TerminologyGuidelineParameterValueObject } from './hooks';
import { EnumContentObject } from 'types/types';
import { DomainStructureObj } from 'components/DomainMenu/types';

export function buildStructure(
  structureArr: DomainStructureObj[],
  nestStructure: TemporalStructureObject,
  id: string,
  level = 0
): void {
  if (nestStructure[id]) {
    const max = nestStructure[id].length;
    for (let i = 0; i < max; i++) {
      const domainObj = nestStructure[id][i];

      structureArr.push({
        ...domainObj,
        level,
        checked: false,
        indeterminate: false,
        childrenIndexes: [],
      });

      const node = structureArr.find(
        (d) => d.internalId === domainObj.internalParentId
      ) as DomainStructureObj;
      node.childrenIndexes.push(structureArr.length - 1);

      buildStructure(
        structureArr,
        nestStructure,
        domainObj.internalId,
        level + 1
      );
    }
  }
}

export function createTemporalStructureObject(
  structureObject: TemporalStructureObject,
  domainObject: EnumContentObject
): TemporalStructureObject {
  if (domainObject.internalParentId) {
    if (!structureObject[domainObject.internalParentId]) {
      structureObject[domainObject.internalParentId] = [];
    }
    structureObject[domainObject.internalParentId].push(domainObject);
  }

  return structureObject;
}

export function buildParentStructure(
  id: string,
  domainArr: DomainStructureObj[],
  fullDisplayStructure: DomainStructureObj[]
) {
  const domainObj = fullDisplayStructure.find(
    (d) => d.internalId === id
  ) as DomainStructureObj;

  if (!domainArr.find((d) => d.internalId === id)) {
    domainArr.splice(0, 0, domainObj);
  }
  if (domainObj.internalParentId) {
    buildParentStructure(
      domainObj.internalParentId,
      domainArr,
      fullDisplayStructure
    );
  }
}

export function updateChecked(
  domainObj: DomainStructureObj,
  selectedDomains: string[],
  structure: DomainStructureObj[]
) {
  domainObj.checked = getIfDomainIsChecked(selectedDomains, domainObj);

  const parent = structure.find(
    (d) => d.internalId === domainObj.internalParentId
  );

  if (parent) {
    updateChecked(parent, selectedDomains, structure);
  }
}

export function getIfDomainIsChecked(
  selectedDomains: string[],
  domainObj: EnumContentObject
) {
  return !!selectedDomains?.includes(domainObj.internalId);
}

export function addAllChildren(
  domainObj: DomainStructureObj,
  structure: DomainStructureObj[],
  affectedDomains: string[]
) {
  if (!domainObj.childrenIndexes.length) {
    return;
  }

  domainObj.childrenIndexes.forEach((index) => {
    const domainObjChild = structure[index];
    affectedDomains.push(domainObjChild.internalId);

    addAllChildren(domainObjChild, structure, affectedDomains);
  });
}

export function calculateAdmittedTermsValue(
  parameterValueObj: TerminologyGuidelineParameterValueObject
) {
  let value = '';

  value += parameterValueObj.scoreAdmittedTerms ? '1' : '0';
  value += parameterValueObj.suggestAdmittedTerms ? '1' : '0';
  value += parameterValueObj.markAdmittedTerms ? '1' : '0';

  return value;
}

export function getFirstDomainName(
  domainData: EnumContentObject[],
  domainNames: string[] = []
) {
  return (
    (
      domainData.find(
        (domain) => domain.internalId === (domainNames || [])[0]
      ) || {}
    )?.displayName || ''
  );
}

export function createDomainsSummary(
  domainData: EnumContentObject[],
  parameterValueObj: TerminologyGuidelineParameterValueObject,
  tBoundToTranslationString: any
): string {
  const domainName: string = getFirstDomainName(
    domainData,
    parameterValueObj.domainNames || []
  );

  const summary =
    parameterValueObj.domainNames.length > 1
      ? tBoundToTranslationString({
          count: parameterValueObj.domainNames?.length - 1 || 0,
          domainName,
          moreDomainsNumber: parameterValueObj.domainNames.length - 1,
        })
      : tBoundToTranslationString();

  return parameterValueObj.domainNames.length === 1 ? domainName : summary;
}
