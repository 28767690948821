import React, {
  ReactElement,
  MouseEvent,
  memo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import isEqual from 'lodash/isEqual';
import Grid from '@mui/material/Grid';
import { DrawerProps } from '@mui/material/Drawer/Drawer';

import {
  DrawerFooter,
  DrawerContentWrapper,
  StyledDrawer,
  StyledDrawerSubtitle,
  StyledDrawerTitle,
  TitleGrid,
} from './styles';
import ButtonPrimary from 'components/ButtonPrimary/ButtonPrimary';
import useResize from 'hooks/useResize';
import CommonDrawerCloseButton from './CommonDrawerCloseButton';
import CommonDrawerSubmitButton from './CommonDrawerSubmitButton';

export interface DrawerTypeInterface {
  type?: 'slim' | 'wide';
}

export interface CommonDrawerInterface extends DrawerTypeInterface {
  cancelButtonLabel?: string;
  cancelAndCloseHandler: () => void;
  children: React.ReactNode;
  drawerTitle: string | React.ReactNode;
  drawerSubTitle?: string;
  drawerIsOpen: boolean;
  footerComponent?: React.ReactElement;
  setDrawerRect?: (x: DOMRect) => void;
  setFooterRect?: (x: DOMRect) => void;
  SplitSaveButtonComponent?: JSX.Element;
  submitButtonClickHandler?: (event: MouseEvent<{ value: unknown }>) => void;
  submitButtonLabel?: string;
  submitDisabled?: boolean;
  submitErrorText?: string;
  handleExit?: () => void;
}

export function CommonDrawer({
  cancelButtonLabel,
  cancelAndCloseHandler,
  children,
  drawerTitle,
  drawerSubTitle,
  drawerIsOpen,
  footerComponent,
  setDrawerRect,
  setFooterRect,
  SplitSaveButtonComponent,
  submitButtonLabel,
  submitButtonClickHandler,
  submitDisabled,
  submitErrorText,
  type,
  handleExit,
}: CommonDrawerInterface): ReactElement {
  const emptyContainer = useRef<HTMLDivElement>(null);
  const {
    setResizeElement: setResizeDrawer,
    resizedHeight: drawerResizedHeight,
    resizeElement: resizeDrawer,
  } = useResize();
  const {
    setResizeElement: setResizeFooter,
    resizedHeight: footerResizedHeight,
    resizeElement: resizeFooter,
  } = useResize();

  useEffect(() => {
    if (drawerResizedHeight && footerResizedHeight && emptyContainer.current) {
      emptyContainer.current.style.marginBottom = `${
        footerResizedHeight + 10
      }px`;
    }

    if (resizeFooter && typeof setFooterRect === 'function') {
      setFooterRect(resizeFooter.getBoundingClientRect());
    }

    if (resizeDrawer && typeof setDrawerRect === 'function') {
      setDrawerRect(resizeDrawer.getBoundingClientRect());
    }
  }, [
    drawerResizedHeight,
    setDrawerRect,
    footerResizedHeight,
    setFooterRect,
    resizeFooter,
    resizeDrawer,
  ]);

  const clickHandler = useCallback(
    (evt: MouseEvent<{ value: unknown }>) => {
      if (submitButtonClickHandler) {
        return submitButtonClickHandler(evt);
      }
    },
    [submitButtonClickHandler]
  );

  const submitButtonProps: {
    onClick?: (evt: MouseEvent<{ value: unknown }>) => void;
    'aria-disabled'?: boolean;
  } = {
    ...(submitDisabled && {
      'aria-disabled': submitDisabled,
    }),
    ...(!submitDisabled && {
      onClick: clickHandler,
    }),
  };

  const drawerProps: DrawerProps = {
    anchor: 'right',
    open: drawerIsOpen,
    onClose: cancelAndCloseHandler,
  };

  const titleGridProps = drawerSubTitle
    ? {
        alignItems: 'flex-start',
        // sx: (theme: Theme) => ({ paddingTop: theme.spacing(2.2) }),
      }
    : {
        alignItems: 'center',
      };

  const drawerTitleId = 'abc'; //drawerTitle?.replaceAll(' ', '-') ?? '';

  return (
    <StyledDrawer
      type={type}
      {...drawerProps}
      ref={setResizeDrawer}
      SlideProps={{
        onExited: handleExit,
        role: 'complementary',
        'aria-labelledby': drawerTitleId,
        'aria-describedby': drawerSubTitle ? `${drawerTitleId}-subtitle` : '',
      }}
    >
      <TitleGrid container {...titleGridProps}>
        <Grid container justifyContent="space-between" alignItems="center">
          <StyledDrawerTitle component="h2" id-={drawerTitleId}>
            {drawerTitle}
          </StyledDrawerTitle>
          <CommonDrawerCloseButton onClose={cancelAndCloseHandler} />
        </Grid>
        {drawerSubTitle && (
          <StyledDrawerSubtitle
            variant="body2"
            id={`${drawerTitleId}-subtitle`}
          >
            {drawerSubTitle}
          </StyledDrawerSubtitle>
        )}
      </TitleGrid>
      <DrawerContentWrapper>
        <form>
          {children}
          <div className="empty" ref={emptyContainer} />
        </form>

        {footerComponent ? (
          <>{footerComponent}</>
        ) : (
          <>
            {(cancelButtonLabel || submitButtonLabel) && (
              <DrawerFooter type={type} ref={setResizeFooter}>
                {cancelButtonLabel && (
                  <ButtonPrimary
                    variant="outlined"
                    onClick={cancelAndCloseHandler}
                  >
                    {cancelButtonLabel}
                  </ButtonPrimary>
                )}
                {submitButtonLabel && (
                  <CommonDrawerSubmitButton
                    SplitSaveButtonComponent={SplitSaveButtonComponent}
                    submitButtonLabel={submitButtonLabel}
                    submitButtonProps={submitButtonProps}
                    submitErrorText={submitErrorText}
                  />
                )}
              </DrawerFooter>
            )}
          </>
        )}
      </DrawerContentWrapper>
    </StyledDrawer>
  );
}

export default memo(CommonDrawer, isEqual);
