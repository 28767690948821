import React, { ReactElement, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { blueGrey } from '@mui/material/colors';

import { HeaderBox, MainBox } from './components';
import CommonTooltip from '../CommonTooltip';

export interface PageLayoutProps {
  children: React.ReactElement | React.ReactElement[];
  focusHeading?: boolean;
  heading: string;
  headerRightSideContent?: ReactElement | null;
  helpIconText?: string;
}

export default function IndexPageLayout({
  children,
  focusHeading = false,
  heading,
  headerRightSideContent = null,
  helpIconText,
}: PageLayoutProps): JSX.Element {
  const headingRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusHeading) {
      headingRef.current?.focus();
    }
  }, [focusHeading]);

  return (
    <section>
      <HeaderBox>
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography
            variant="h1"
            ref={headingRef}
            tabIndex={-1}
            sx={(theme) => ({
              paddingRight: theme.spacing(0.6),
              letterSpacing: theme.typography.pxToRem(0.24),
              '&:focus': {
                outline: 'none',
              },
            })}
          >
            {heading}
          </Typography>
          {helpIconText && (
            <CommonTooltip
              title={helpIconText}
              type="yellow"
              placement="right-start"
            >
              <HelpOutlineOutlinedIcon
                tabIndex={0}
                aria-hidden={false}
                sx={(theme) => ({
                  color: theme.palette.custom.botticelli,
                  padding: theme.spacing(0.6),
                  height: theme.spacing(5.6),
                  width: theme.spacing(5.6),
                  borderRadius: theme.spacing(5),
                  '&:hover, &:focus': {
                    color: blueGrey[700],
                    backgroundColor: theme.palette.common.white,
                    outline: 'none',
                  },
                })}
              />
            </CommonTooltip>
          )}
        </Box>
        {headerRightSideContent}
      </HeaderBox>
      <MainBox>{children}</MainBox>
    </section>
  );
}
