import React, { ReactElement } from 'react';
import InternalServerErrorComponent from 'pages/InternalServerErrorComponent/InternalServerErrorComponent';
import NotFoundErrorPage from 'pages/NotFoundErrorPage';
import { ServerError } from 'errors/ServerError';

interface ErrorHandlerProps {
  error: Error | null;
}

/**
 * This error handler accepts an error as a prop and based on its type decides which error component to display
 */
const ErrorHandler = (props: ErrorHandlerProps): ReactElement => {
  if (props.error instanceof ServerError) {
    return <InternalServerErrorComponent />;
  } else {
    return <NotFoundErrorPage />;
  }
};

export default ErrorHandler;
