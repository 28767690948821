import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip, { chipClasses } from '@mui/material/Chip';
import { dialogClasses } from '@mui/material/Dialog';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { DrawerContent } from 'components/CommonDrawer';
import Select from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {
  accordionSummaryClasses,
  backdropClasses,
  TypographyProps,
} from '@mui/material';
import {
  brown,
  grey,
  indigo,
  lime,
  pink,
  purple,
  teal,
} from '@mui/material/colors';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ChipWithMaxWidth from './ChipWithMaxWidth';
import { StyledAccordionSummary } from 'components/FixedAccordion';
import DialogBox from 'components/DialogBox';

export const TextTypeOption = styled(Grid)(() => ({
  '& .MuiIconButton-root svg': {
    visibility: 'hidden',
    cursor: 'pointer',
  },
  '&:hover, &:focus, &:focus-within': {
    '& .MuiIconButton-root svg': {
      visibility: 'visible',
    },
  },
}));
export const StyledEditDialog = styled(DialogBox)(({ theme }) => ({
  left: 'unset',
  width: theme.spacing(70),
  boxSizing: 'border-box',
  [`&.${dialogClasses.root} .${dialogClasses.paperScrollPaper}`]: {
    margin: theme.spacing(3),
    padding: theme.spacing(3, 0, 0),
    position: 'absolute',
    display: 'block',
    flexDirection: 'unset',
    top: theme.spacing(2),
    width: theme.spacing(60),
  },
  [`&.${dialogClasses.root} .${backdropClasses.root}`]: {
    position: 'absolute',
    // because backdrop has inline style with opacity 1
    backgroundColor: theme.palette.custom.nightSkyWith6Opacity,
  },
  [`&.${dialogClasses.root} .${dialogClasses.container}`]: {
    width: theme.spacing(70),
  },
  [`& .${dialogClasses.paper}`]: {
    minWidth: theme.spacing(64),
  },
}));

export const TargetNameTitleContainer = styled('div');

export const InputContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const OverflowedTargetName = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

export const AssignedTargetName = styled(OverflowedTargetName)<
  TypographyProps & { component: string }
>(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.32),
  fontSize: theme.typography.pxToRem(16),
  margin: theme.spacing(0, 0, 2, 1),
  fontWeight: theme.typography.fontWeightMedium,
}));

export const TargetAssignmentsContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0, 6, 0, 6),
}));

export const AssignTargetsDescription = styled('p')(({ theme }) => ({
  textAlign: 'center',
  letterSpacing: theme.typography.pxToRem(0.36),
  fontSize: theme.typography.pxToRem(18),
  marginTop: theme.spacing(10),
}));

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& button': {
    opacity: '0',
  },
  '&:hover, &:focus-within': {
    '& button': {
      opacity: '1',
    },
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  fullWidth: {
    width: '100%',
  },
}));

export const AndChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '19px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: grey[400],
  borderRadius: '8px',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [`& .${chipClasses.label}`]: {
    padding: theme.spacing(0, 0.8),
    color: grey[600],
    letterSpacing: '0.26px',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
}));

export const AssignedToAllChip = styled(ChipWithMaxWidth)(({ theme }) => ({
  backgroundColor: pink[50],
  fontStyle: 'italic',
  fontWeight: theme.typography.fontWeightBold,
  color: pink[900],
  '& div': {
    paddingRight: theme.spacing(0.4),
  },
}));

export const UsernameChip = styled(ChipWithMaxWidth)({
  backgroundColor: indigo[50],
  color: indigo[900],
});

export const RoleChip = styled(ChipWithMaxWidth)({
  backgroundColor: teal[50],
  color: teal[900],
});

export const CustomFieldChip = styled(ChipWithMaxWidth)({
  backgroundColor: purple[50],
  color: purple[900],
});

export const DocumentReferenceChip = styled(ChipWithMaxWidth)(({ theme }) => ({
  backgroundColor: lime[100],
  [`& .${chipClasses.label}`]: {
    color: theme.palette.custom.brownTextColor,
  },
}));

export const LanguageDetectionChip = styled(ChipWithMaxWidth)({
  backgroundColor: brown[50],
  [`& .${chipClasses.label}`]: {
    color: brown[800],
  },
});

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: theme.spacing(2),
  letterSpacing: theme.typography.pxToRem(0.32),
  '& p': {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: grey[100],
  '& .MuiSelect-select:focus': {
    backgroundColor: grey[100],
  },
  '& .MuiSelect-icon': {
    // fontSize: 'large',
    top: '-15%',
  },
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  borderTop: `1px solid ${grey[300]}`,
  padding: theme.spacing(4),
  display: 'block',
  position: 'sticky',
  bottom: 0,
  '& button': {
    marginRight: theme.spacing(2),
  },
}));
export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  '& > :last-child': {
    borderBottom: 'none',
  },
}));
export const SlimRowGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 4),
})) as typeof Grid;

export const MediumRowGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3.2, 4),
}));

export const RowGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const RowGridWithSmallBottomPadding = styled(RowGrid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

export const SlimRowBorderGrid = styled(RowGrid)({
  borderBottom: `1px solid ${grey[300]}`,
  padding: 0,
  margin: 0,
});

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: grey[700],
  [`& .${inputLabelClasses.focused}`]: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.main,
  },
}));

export const StyledDrawerContent = styled(DrawerContent)(({ theme }) => ({
  //adding 1px to make sure the box shadow of the contained card is being shown
  padding: theme.spacing(0.2, 3, 0, 3),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0),
  margin: theme.spacing(0, 0, 3, 0),
  width: theme.spacing(62),
  flexDirection: 'row',
  alignItems: 'center',
  '& button': {
    opacity: '0',
  },
  '&:hover': {
    '& button': {
      opacity: '1',
    },
  },
  boxShadow: '0px 1px 3px #00000066',
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&.MuiCardContent-root:last-child': {
    padding: theme.spacing(2, 1, 1, 1),
  },
  width: '100%',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  height: theme.typography.pxToRem(32),
  fontSize: theme.typography.pxToRem(16),
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  '& p': {
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: theme.typography.pxToRem(0.28),
  },
  padding: theme.spacing(1, 2),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.28),
  lineHeight: theme.typography.pxToRem(21),
  fontWeight: theme.typography.fontWeightRegular,
}));

export const WarningText = styled(Typography)(({ theme }) => ({
  letterSpacing: theme.typography.pxToRem(0.24),
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: grey[100],
  borderRadius: theme.shape.borderRadius,
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused': {
      backgroundColor: 'white',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  paddingRight: theme.spacing(0),
  border: 0,
  '& input': {
    padding: theme.spacing(1.4, 2),
    border: 0,
    fontSize: theme.typography.pxToRem(14),
    '&::placeholder': {
      fontSize: theme.typography.pxToRem(14),
      letterSpacing: theme.typography.pxToRem(0.28),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.custom.doveGrey,
      opacity: 1,
    },
  },
  '& p': {
    margin: theme.spacing(0.5, 0, 0, 2),
  },
}));

export const StyledWarningGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(6),
  flexWrap: 'nowrap',
}));

export const UnassignedTargetsAccordionSummary = styled(StyledAccordionSummary)(
  ({ theme }) => ({
    margin: theme.spacing(0, 4 / 5, 0, 3.2),
    marginBottom: theme.spacing(0),
    boxSizing: 'content-box',
    borderRadius: 25,
    padding: theme.spacing(2, 0, 3, 0),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [`& .${accordionSummaryClasses.content}`]: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
      backgroundColor: 'transparent;',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: theme.spacing(1),
    },
  })
);

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing(0),
  overflowY: 'scroll',
}));

export const StyleUnassignedCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
  margin: theme.spacing(0, 0, 2, 0),
  flexDirection: 'row',
  alignItems: 'center',
  boxShadow: '0px 1px 3px #00000066',
  '&:hover': {
    boxShadow: '0px 3px 6px #0000004D',
  },
}));

export const StyledUnassignedCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2, 1, 2, 3),
  overflow: 'hidden',
}));

export const UnassignedTargetsTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: 0.36,
  color: theme.palette.text.primary,
}));

export const UnassignedTargetName = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: 0.32,
}));

export const StyledEditUnassignedButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: theme.typography.pxToRem(16),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: pink[600],
  color: theme.palette.common.white,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(2),
  height: theme.spacing(5.2),
  width: theme.spacing(5.2),
  [`& .${chipClasses.label}`]: {
    padding: 0,
    lineHeight: '26px',
  },
}));

export const ScrollableCheckboxList = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  overflowX: 'hidden',
  //paddingRight: theme.spacing(4)
}));

export const UnassignedTargetsCardContainer = styled('div')(({ theme }) => ({
  scrollbarWidth: 'thin',
  padding: theme.spacing(0.2, 3, 0, 3), //adding 1px to make sure the box shadow of the contained card is being shown
}));
