import { Dispatch, SetStateAction, useContext, useState } from 'react';

import { TargetNew } from '../TargetPage/types';
import {
  TARGET_ASSIGNMENT_INDEX_PATH,
  TARGET_PATH,
  TARGETS_PATH,
} from '../TargetPage/constants';
import AuthContext from 'contexts/AuthContext';
import { fetchJsonFromAPI, fetchPlainResponse, HttpMethod } from 'utils/fetch';
import { UseSortResult } from 'hooks/useSort';
import { useIsOpen, UseIsOpenResult } from 'hooks/useIsOpen';
import { TargetAssignment } from 'sections/Targets/types';
import { TargetIndexItem } from 'types/types';
import { getUnassignedTargets } from './helpers';
import { TARGET_SERVICE_PATH } from 'constants/constants';
import useFetchTargets, { UseFetchTargetsResult } from 'hooks/useFetchTargets';
import usePrivileges, { TargetsUserRights } from '../../hooks/usePrivileges';

export interface UseTargetIndexPageHookResult {
  closeDeleteConfirmationDialog: (
    deleteIt: boolean,
    target: TargetIndexItem
  ) => Promise<void>;
  closeDialogTargetAssignment: UseIsOpenResult['close'];
  closeDrawerCreateTarget: () => void;
  closeDrawerTargetAssignments: UseIsOpenResult['close'];
  closeForCopyTargetDialog: () => void;
  createTarget: (target: TargetNew) => Promise<void>;
  dialogTargetAssignmentIsOpen: UseIsOpenResult['elementIsOpen'];
  drawerCreateTargetIsOpen: UseIsOpenResult['elementIsOpen'];
  drawerTargetAssignmentsIsOpen: UseIsOpenResult['elementIsOpen'];
  forCopyTargetDialog: TargetIndexItem | undefined;
  openDialogTargetAssignment: UseIsOpenResult['open'];
  openDrawerTargetAssignments: UseIsOpenResult['open'];
  privileges: TargetsUserRights;
  saveTargetAssignment: (
    target: TargetAssignment,
    id?: string
  ) => Promise<void>;
  setDrawerCreateTargetIsOpen: UseIsOpenResult['setElementIsOpen'];
  setForCopyTargetDialog: Dispatch<SetStateAction<TargetIndexItem | undefined>>;
  setSorting: UseSortResult['setSorting'];
  setTargetForDeleteConfirmation: Dispatch<
    SetStateAction<TargetIndexItem | undefined>
  >;
  sortedTargets: TargetIndexItem[];
  sortField: UseSortResult['sortField'];
  sortOrder: UseSortResult['sortOrder'];
  targetForDeleteConfirmation: TargetIndexItem | undefined;
  targetsResult: UseFetchTargetsResult['targetsResult'];
  unassignedTargets: TargetAssignment[];
}

export function useTargetIndexPageHook(): UseTargetIndexPageHookResult {
  const {
    elementIsOpen: drawerCreateTargetIsOpen,
    setElementIsOpen: setDrawerCreateTargetIsOpen,
  } = useIsOpen();

  const {
    close: closeDrawerTargetAssignments,
    elementIsOpen: drawerTargetAssignmentsIsOpen,
    open: openDrawerTargetAssignments,
  } = useIsOpen();

  const {
    close: closeDialogTargetAssignment,
    elementIsOpen: dialogTargetAssignmentIsOpen,
    open: openDialogTargetAssignment,
  } = useIsOpen();

  //  Opens the DeleteConfirmationDialog if set to a target.
  const [targetForDeleteConfirmation, setTargetForDeleteConfirmation] =
    useState<TargetIndexItem | undefined>();

  //  Opens the CopyTargetDialog if set to a target.
  const [forCopyTargetDialog, setForCopyTargetDialog] = useState<
    TargetIndexItem | undefined
  >();

  const { targetsResult, setSorting, sortedTargets, sortField, sortOrder } =
    useFetchTargets();

  const [authToken] = useContext(AuthContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function createTarget(target: TargetNew): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return fetchJsonFromAPI<any, any>(
      authToken,
      `${TARGET_SERVICE_PATH}/${TARGETS_PATH}`,
      { method: HttpMethod.POST, body: target }
    );
  }

  const privileges = usePrivileges();

  function closeDrawerCreateTarget() {
    setDrawerCreateTargetIsOpen(false);
    targetsResult.refetch();
  }

  function closeForCopyTargetDialog() {
    setForCopyTargetDialog(undefined);
    targetsResult.refetch();
  }

  async function closeDeleteConfirmationDialog(
    deleteIt: boolean,
    target: TargetIndexItem
  ) {
    setTargetForDeleteConfirmation(undefined);

    if (deleteIt) {
      const basePath = `${TARGET_SERVICE_PATH}/${TARGET_PATH.replace(
        ':id',
        target.uuid
      )}`;
      const path = target.comment
        ? `${basePath}?comment=${target.comment}`
        : basePath;

      await fetchJsonFromAPI<unknown, unknown>(authToken, path, {
        method: HttpMethod.DELETE,
      });
    }
    targetsResult.refetch();
  }

  const unassignedTargets: TargetAssignment[] =
    getUnassignedTargets(sortedTargets);

  async function saveTargetAssignment(target: TargetAssignment, id?: string) {
    const path = `${TARGET_SERVICE_PATH}/${TARGET_ASSIGNMENT_INDEX_PATH}${
      id ? `/${id}` : ''
    }`;
    await fetchPlainResponse<TargetAssignment>(authToken, path, {
      method: id ? HttpMethod.PUT : HttpMethod.POST,
      body: target,
    });

    targetsResult.refetch();
  }

  return {
    closeDeleteConfirmationDialog,
    closeDialogTargetAssignment,
    closeDrawerCreateTarget,
    closeDrawerTargetAssignments,
    closeForCopyTargetDialog,
    createTarget,
    dialogTargetAssignmentIsOpen,
    drawerCreateTargetIsOpen,
    drawerTargetAssignmentsIsOpen,
    forCopyTargetDialog,
    openDialogTargetAssignment,
    openDrawerTargetAssignments,
    privileges,
    saveTargetAssignment,
    setDrawerCreateTargetIsOpen,
    setForCopyTargetDialog,
    setSorting,
    setTargetForDeleteConfirmation,
    sortedTargets,
    sortField,
    sortOrder,
    targetForDeleteConfirmation,
    targetsResult,
    unassignedTargets,
  };
}
