import React, { useState, useContext, useReducer } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CommonTooltip from 'components/CommonTooltip';
import GeneralInfo from './components/GeneralInfo';
import RolesInfo from './components/RolesInfo';
import { ReactComponent as IconLock } from 'components/FilledTextField/IconLock.svg';
import TabPanel from './components/TabPanel';
import { DetailsPageLayout } from 'components/PageLayout';
import {
  USER_AND_ROLES_SET_API_TOKENS_FOR_OTHERS,
  USER_AND_ROLES_EDIT_USER,
} from 'constants/privileges';
import PrivilegesContext from 'contexts/PrivilegesContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import CreateApiToken from './components/CreateApiToken';
import UsageInfo from './components/UsageInfo';
import useTitle from 'hooks/useTitle';
import { useTranslation } from 'react-i18next';
import { WithErrorHandling } from '../UsersIndexPage/components/WithErrorHandling';
import { a11yProps, reducer, useGetUser } from './utils';
import { User } from 'sections/Users/types';
import { StyledTab, StyledTabs } from './styles';

const UserDetails = () => {
  const { t } = useTranslation();
  const { data, error } = useGetUser();
  const user = data?.data;

  useTitle(`${user?.username} - ${t('users.title')} - ${t('shared.app.name')}`);

  if (error || !user) {
    return null;
  }

  return <UserDetailsContent fetchedUser={user} />;
};

const UserDetailsContent = ({ fetchedUser }: { fetchedUser: User }) => {
  const { t } = useTranslation();
  const currentUser = useContext(CurrentUserContext);
  const userPrivileges = useContext(PrivilegesContext);

  const [user, dispatch] = useReducer(reducer, fetchedUser);
  const [value, setValue] = useState(0);

  const {
    username,
    fullName,
    createdOn,
    lastIntegrationAccess,
    checkingFrequency,
    licenseType,
  } = user;

  const hasUserEditRights = [USER_AND_ROLES_EDIT_USER].some((p) =>
    userPrivileges.includes(p)
  );

  const isBuiltInUserAndIsRolesTabOpen =
    licenseType === 'builtin' && value === 1;

  const hasSetTokenRights = [USER_AND_ROLES_SET_API_TOKENS_FOR_OTHERS].some(
    (p) => userPrivileges.includes(p)
  );

  const displayApiTokenSection =
    (!hasSetTokenRights &&
      username === currentUser?.username &&
      currentUser?.username !== 'admin') ||
    !(
      !hasSetTokenRights ||
      (hasSetTokenRights && username === 'admin') ||
      (hasSetTokenRights && currentUser?.username === 'admin')
    );

  return (
    <WithErrorHandling>
      <DetailsPageLayout
        backLink="/user-management/users"
        backIconText={t('user.a11y.backIcon')}
        heading={username + (fullName ? ' (' + fullName + ')' : '')}
      >
        <Box p={6}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'custom.gray93',
              position: 'relative',
            }}
          >
            <Box sx={{ float: 'right' }}>
              <CommonTooltip
                title={<>{t('users.tooltips.readOnlyTextField')}</>}
              >
                <IconButton
                  disableRipple
                  aria-label={t('users.tooltips.readOnlyTextField')}
                  sx={{
                    '&:hover, &:focus': {
                      backgroundColor: 'custom.whiteSmoke',
                    },
                    display: `${
                      hasUserEditRights && !isBuiltInUserAndIsRolesTabOpen
                        ? 'none'
                        : 'flex'
                    }`,
                  }}
                >
                  <IconLock />
                </IconButton>
              </CommonTooltip>
            </Box>
            <StyledTabs
              value={value}
              onChange={(_event: React.SyntheticEvent, newValue: number) => {
                setValue(newValue);
              }}
              aria-label={t('user.a11y.tabs.label')}
            >
              <StyledTab
                label={t('shared.navigation.general')}
                {...a11yProps(0)}
              />
              <StyledTab
                label={t('shared.navigation.roles')}
                {...a11yProps(1)}
              />
              <StyledTab
                label={t('shared.navigation.usage')}
                {...a11yProps(2)}
              />
              {displayApiTokenSection && (
                <StyledTab
                  label={t('shared.navigation.apiToken')}
                  {...a11yProps(3)}
                />
              )}
            </StyledTabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GeneralInfo
              user={user}
              dispatch={dispatch}
              editingAllowed={hasUserEditRights}
            />
          </TabPanel>
          <TabPanel value={value} index={1} tabIndex={0}>
            <RolesInfo userId={user.id} />
          </TabPanel>
          <TabPanel value={value} index={2} tabIndex={0}>
            <UsageInfo
              createdOn={createdOn}
              lastAccess={lastIntegrationAccess}
              checkingFrequency={checkingFrequency}
            />
          </TabPanel>
          {displayApiTokenSection && (
            <TabPanel value={value} index={3} tabIndex={0}>
              <CreateApiToken userId={user.id} username={username} />
            </TabPanel>
          )}
        </Box>
      </DetailsPageLayout>
    </WithErrorHandling>
  );
};

export default UserDetails;
