import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

const TypographyH2 = ({ children, ...rest }: TypographyProps) => (
  <Typography variant="h2" {...rest}>
    {children}
  </Typography>
);

const StyledListHeading = styled(TypographyH2)(({ theme }) => ({
  marginBottom: theme.spacing(1 / 2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(18),
  color: theme.palette.custom.doveGrey,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.typography.pxToRem(0.36),
}));

export const ListHeading = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return <StyledListHeading>{t(title)}</StyledListHeading>;
};
