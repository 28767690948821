import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';

import CommonCheckboxWithLabel from 'components/CommonCheckboxWithLabel';
import { StyledTitle } from './styles';
import RoleWithPrivileges from './components/RoleWithPrivileges';
import {
  StyledPrivilegeName,
  StyledRoleName,
} from './components/RoleWithPrivileges/styles';
import { SUPER_ADMINISTRATOR } from '../../../UsersIndexPage/constants';
import { sortRoles } from './utils';
import { Role } from '../../../../types';
import { useRoleInfo } from './hooks';

interface RolesInfoProps {
  userId: string;
}

export default function RolesInfo({ userId }: RolesInfoProps) {
  const { t } = useTranslation();

  const {
    errorData,
    userData,
    canEditUsers,
    isCurrentUserSuperAdmin,
    rolesData,
    roles,
    handleChangeRoles,
    licenseType,
    username,
  } = useRoleInfo(userId);

  const availableRoles = rolesData?.data
    .filter(({ name }) =>
      !isCurrentUserSuperAdmin ? name !== SUPER_ADMINISTRATOR : true
    )
    .filter(
      ({ id: roleId }) =>
        !roles?.some(({ id: userRoleId }) => userRoleId === roleId)
    );

  const isSuperAdminRoleAndAssignable = (name: Role['name']) =>
    isCurrentUserSuperAdmin ||
    name !== SUPER_ADMINISTRATOR ||
    !roles ||
    !(roles?.length > 1);

  if (errorData || !userData) {
    return <></>;
  }

  return (
    <form autoComplete="off">
      <Box>
        {(canEditUsers && (
          <Box overflow={'auto'}>
            <Box sx={{ float: 'left' }} width={500} pr={10}>
              <StyledTitle variant="h2" mt={6} mb={4}>
                <Trans
                  i18nKey="roles.labels.assignedRolesTitle"
                  {...{
                    username,
                  }}
                >
                  Roles assigned to {{ username }}
                </Trans>
              </StyledTitle>
              {licenseType !== 'builtin' && (
                <Typography variant="body2" pb={4}>
                  {t('roles.labels.assignedRolesInfo')}
                </Typography>
              )}
              {sortRoles(roles).map(
                ({ id: userRoleId, name, default: defaultRole }) => (
                  <Box key={userRoleId} pb={4}>
                    <CommonCheckboxWithLabel
                      tooltip={
                        !isSuperAdminRoleAndAssignable(name)
                          ? t('roles.labels.notAssignableRolesInfo')
                          : undefined
                      }
                      checked
                      disabled={
                        licenseType === 'builtin' || roles?.length === 1
                      }
                      {...(!isSuperAdminRoleAndAssignable(name) && {
                        'aria-disabled': true,
                      })}
                      color="primary"
                      aria-labelledby={name}
                      label={
                        <RoleWithPrivileges
                          rolesData={rolesData}
                          userRoleId={userRoleId}
                          name={name}
                        />
                      }
                      {...(!isSuperAdminRoleAndAssignable(name) && {
                        onClick: (e) => e.preventDefault(),
                      })}
                      onChange={() =>
                        handleChangeRoles({
                          id: userRoleId,
                          name: name,
                          default: defaultRole,
                        })
                      }
                    />
                  </Box>
                )
              )}
            </Box>
            {licenseType !== 'builtin' && (
              <Box sx={{ float: 'left' }} width={500}>
                <StyledTitle variant="h2" mt={6} mb={4}>
                  {t('roles.labels.availableRolesTitle')}
                </StyledTitle>
                {sortRoles(availableRoles).map(
                  ({ id: userRoleId, name, default: defaultRole }) => (
                    <Box key={userRoleId} pb={4}>
                      <CommonCheckboxWithLabel
                        checked={false}
                        aria-labelledby={name}
                        color="primary"
                        label={
                          <RoleWithPrivileges
                            rolesData={rolesData}
                            userRoleId={userRoleId}
                            name={name}
                          />
                        }
                        onChange={() =>
                          handleChangeRoles({
                            id: userRoleId,
                            name: name,
                            default: defaultRole,
                          })
                        }
                      />
                    </Box>
                  )
                )}
                {!isCurrentUserSuperAdmin && (
                  <Box pt={6}>
                    <StyledTitle variant="h2" mb={2}>
                      {t('roles.labels.notAssignableRolesTitle')}
                    </StyledTitle>
                    <Typography fontSize={13} fontStyle={'italic'}>
                      {t('roles.labels.notAssignableRolesInfo')}
                    </Typography>
                    <StyledRoleName variant="body2" pt={2}>
                      {t('roles.labels.superAdministrator')}
                    </StyledRoleName>
                    <StyledPrivilegeName variant="body2">
                      {t('roles.labels.superAdministratorInfo')}
                    </StyledPrivilegeName>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )) || (
          <Box width={500} pr={10}>
            <StyledTitle variant="h2" mt={6} mb={4}>
              <Trans
                i18nKey="roles.labels.assignedRolesTitle"
                {...{
                  username,
                }}
              >
                Roles Assigned to {{ username }}
              </Trans>
            </StyledTitle>
            {sortRoles(roles)?.map(({ id: userRoleId, name }) => (
              <Box mt={4} key={userRoleId}>
                <RoleWithPrivileges
                  rolesData={rolesData}
                  userRoleId={userRoleId}
                  name={name}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </form>
  );
}
