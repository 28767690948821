import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  SnackbarProvider,
  SnackbarKey,
  SnackbarProviderProps,
} from 'notistack';
import IconButton from '@mui/material/IconButton';

import NotistackThemeResponsiveSnackbar from '../NotistackThemeResponsiveSnackbar';
import { DRAWER_WIDTH } from '../../MainLayout';
import { ReactComponent as IconCloseNotification } from './IconCloseNotification.svg';
import { BlackTooltip } from 'components/CommonTooltip';

declare module 'notistack' {
  interface VariantOverrides {
    default: {
      snackbarContentProps?: React.HTMLAttributes<HTMLDivElement>;
    };
  }
}

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '& .containerAnchorOriginBottomCenter': {
    [theme.breakpoints.up('lg')]: {
      left: `calc(50vw + ${DRAWER_WIDTH / 2}px) !important`,
    },
  },
}));

export default function NotistackCustomSnackbarProvider({
  children,
}: SnackbarProviderProps) {
  const { t } = useTranslation();
  const snackbarProviderRef = useRef<SnackbarProvider>(null);

  return (
    <StyledSnackbarProvider
      ref={snackbarProviderRef}
      Components={{
        default: NotistackThemeResponsiveSnackbar,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={7000}
      action={(snackbarId: SnackbarKey) => (
        <BlackTooltip
          title={<>{t('shared.actions.notification.hide')}</>}
          placement="top"
        >
          <IconButton
            disableRipple
            aria-label={t('shared.actions.notification.hide')}
            sx={{
              '&:hover, &:focus': (tA) => ({
                backgroundColor: `${tA.palette.custom.botticelli}33`,
              }),
            }}
            onClick={() =>
              snackbarProviderRef &&
              snackbarProviderRef?.current?.closeSnackbar(snackbarId)
            }
          >
            <IconCloseNotification />
          </IconButton>
        </BlackTooltip>
      )}
    >
      {children}
    </StyledSnackbarProvider>
  );
}
