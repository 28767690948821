import { styled, Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Grid, Tab, Tabs, Typography, TypographyProps } from '@mui/material';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import { blueGrey, grey } from '@mui/material/colors';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ButtonPrimary from 'components/ButtonPrimary';
import CustomCheckbox from 'components/CustomCheckbox';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 25,
  height: 32,
  width: 32,
  float: 'right',
  display: 'inline-block',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  padding: 0,
  '&.MuiIconButton-root': {
    borderRadius: 25,
    height: 32,
    width: 32,
    float: 'right',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: 0,
  },
}));
export const RedResetButton = styled(ButtonPrimary)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const StyledWarningText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.typography.pxToRem(0.24),
  marginLeft: theme.spacing(1),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: theme.typography.pxToRem(0.28),
}));

export const TargetInfoHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
}));

export const GoalDescriptionIconContainer = styled(Grid)(({ theme }) => ({
  height: theme.spacing(6.4),
  width: theme.spacing(6.4),
  padding: theme.spacing(1),
  borderRadius: 25,
  display: 'inline-flex',
  backgroundColor: 'transparent',
  outline: 'none',
  '&:hover, &:focus': {
    backgroundColor: blueGrey[50],
  },
}));

export const GoalDescriptionIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  color: blueGrey[300],
  padding: theme.spacing(1),
  width: theme.spacing(6.4),
  height: theme.spacing(6.4),
  borderRadius: theme.spacing(5),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`& .${formControlLabelClasses.label}`]: {
    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
}));

export const StyledCheckbox = styled(CustomCheckbox)({
  paddingTop: 0,
});

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: theme.spacing(1, 2, 2),
  paddingLeft: theme.spacing(1.6),
  paddingTop: theme.spacing(1.8),
  backgroundColor: grey[50],
  border: `1px solid ${grey[300]}`,
  borderRadius: '4px',
}));

export const StyledWPAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(1.8, 3, 8, 1.6),
    margin: theme.spacing(1, 2, 2),
    backgroundColor: grey[50],
    borderRadius: '4px',
    border: `1px solid ${theme.palette.custom.botticelli}`,
  })
);

export const GridRow = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export const SideGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
}));

export const GoalsSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(2, 0, 0),
}));

export const headerIconsSx = (theme: Theme) => ({
  color: theme.palette.custom.botticelli,
  marginRight: theme.spacing(2),
});

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -4, 1, -4),
}));

export const StyledSubsectionDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -7, 1, -7),
}));

export const GoalName = styled(Typography)<
  TypographyProps & { component: string }
>(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(4),
  fontSize: theme.typography.pxToRem(24),
  letterSpacing: theme.typography.pxToRem(0.24),
  fontWeight: theme.typography.fontWeightRegular,
  display: 'inline-block',
  lineHeight: theme.typography.pxToRem(32),
}));

export const StyledTabs = styled(Tabs)({
  borderBottom: 'none',
  minHeight: '0',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& button:first-of-type': {
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
    borderRight: 'none',
  },

  '& button:last-child': {
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  '& button': {
    marginRight: 0,
  },
});

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(18),
  paddingTop: theme.typography.pxToRem(8),
  minHeight: theme.spacing(7.2),
  minWidth: theme.spacing(40),
  paddingBottom: theme.typography.pxToRem(8),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: blueGrey['A100'],
  color: blueGrey[900],
  '&:hover': {
    backgroundColor: blueGrey[50],
    color: blueGrey[900],
    opacity: 1,
  },
  '&.Mui-selected': {
    backgroundColor: blueGrey[50],
    color: blueGrey[900],
  },
  '&.Mui-focusVisible': {
    backgroundColor: blueGrey[50],
    color: blueGrey[900],
  },
}));
