import React from 'react';
import { useTranslation } from 'react-i18next';
import TransWrapper from 'components/TransWrapper';
import PageContentWrapper from 'components/PageContentWrapper';
import theme from '../../theme';
import {
  StyledContent,
  StyledDescription,
  StyledHeading,
  StyledMainBox,
} from './styles';
import Box from '@mui/system/Box';

export const Home = () => {
  const { t } = useTranslation();

  return (
    <PageContentWrapper pageTitle={t('home.title')}>
      <StyledMainBox>
        <Box>
          <StyledHeading variant="h2">
            {t('home.landingPageHeadline')}
          </StyledHeading>

          <StyledDescription variant="h3">
            {t('home.landingPageDescription')}
          </StyledDescription>

          <StyledContent>
            <TransWrapper
              i18nKey="home.landingPageText"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                a: <a style={{ color: theme.palette.primary.main }} />,
              }}
            />
          </StyledContent>
        </Box>
      </StyledMainBox>
    </PageContentWrapper>
  );
};
