import React from 'react';
import { useTranslation } from 'react-i18next';
import { DraggableProvided } from 'react-beautiful-dnd';
import Grid from '@mui/material/Grid';

import {
  AssignedTargetName,
  AssignedToAllChip,
  CardContainer,
  CustomFieldChip,
  RoleChip,
  StyledCard,
  StyledCardContent,
  UsernameChip,
} from './styles';
import TargetAssignmentsEditButton from './TargetAssignmentsEditButton';
import { Roles, User } from './types';
import DocumentCriteriaCardSection from './DocumentCriteriaCardSection';
import CommonTooltip from 'components/CommonTooltip';
import { useOverflowText } from 'hooks/useOverflowText';
import { TargetAssignment } from '../../../../types';
import TargetAssignmentChipList from './TargetAssignmentChipList';
import TargetAssignmentCustomFieldChipList from './TargetAssignmentCustomFieldChipList';
import { indigo, purple, teal } from '@mui/material/colors';

interface TargetAssignmentCardProps {
  footerTop: number | undefined;
  openDialogTargetAssignment: () => void;
  prov?: DraggableProvided;
  readOnly?: boolean;
  roleList: Roles[];
  setTargetInEdit: (target: TargetAssignment) => void;
  setTargetInEditIsUnassigned: (value: boolean) => void;
  target: TargetAssignment;
  userList: User[];
}
export default function TargetAssignmentCard({
  footerTop,
  openDialogTargetAssignment,
  prov,
  readOnly = false,
  roleList,
  setTargetInEdit,
  setTargetInEditIsUnassigned,
  target,
  userList,
}: Readonly<TargetAssignmentCardProps>): JSX.Element {
  const { t } = useTranslation();
  const { setElementRef, isOverflowed } = useOverflowText();
  const showBatchCheckingCriteria =
    target.documentCustomFieldCriteria.length > 0 ||
    target.documentReferenceCriteria.length > 0 ||
    target.enableLanguageDetection;

  return (
    <CardContainer
      key={target.targetUuid}
      {...(!readOnly &&
        prov && {
          ...prov.draggableProps,
          ...prov.dragHandleProps,
          ref: prov.innerRef,
        })}
    >
      <StyledCard>
        <StyledCardContent>
          <Grid justifyContent="space-between" container>
            <CommonTooltip title={isOverflowed ? target.targetName : ''}>
              <AssignedTargetName
                component="h3"
                ref={(node) => node && setElementRef(node)}
                lang={target.targetLanguageAbbreviation}
              >
                {target.targetName}
              </AssignedTargetName>
            </CommonTooltip>

            {!readOnly && (
              <TargetAssignmentsEditButton
                onClick={() => {
                  setTargetInEdit(target);
                  openDialogTargetAssignment();
                  setTargetInEditIsUnassigned(false);
                }}
              />
            )}
          </Grid>
          <Grid sx={(theme) => ({ marginLeft: theme.spacing(1) })}>
            {target.assignedToAll && (
              <AssignedToAllChip
                labelText={t('capture.labels.assignedToAll')}
              />
            )}{' '}
            {!target.assignedToAll &&
              (target.userUuids.length > 5 ? (
                <TargetAssignmentChipList
                  Chip={UsernameChip}
                  dataList={userList}
                  hoverColor={indigo[100]}
                  footerTop={footerTop}
                  labelText={`${t('capture.labels.users')} (${
                    target.userUuids.length
                  })`}
                  target={target}
                  targetPropertyIdsList={target.userUuids}
                />
              ) : (
                target.userUuids?.map((userId) => (
                  <UsernameChip
                    key={userId}
                    labelText={
                      userList.find((userObject) => userObject.uuid === userId)
                        ?.name || ''
                    }
                  />
                ))
              ))}
            {!target.assignedToAll &&
              (target.roleUuids.length > 5 ? (
                <TargetAssignmentChipList
                  Chip={RoleChip}
                  dataList={roleList}
                  footerTop={footerTop}
                  hoverColor={teal[100]}
                  labelText={`${t('capture.labels.roles')} (${
                    target.roleUuids.length
                  })`}
                  target={target}
                  targetPropertyIdsList={target.roleUuids}
                />
              ) : (
                target.roleUuids?.map((roleId) => (
                  <RoleChip
                    key={roleId}
                    labelText={
                      roleList.find((roleObject) => roleObject.uuid === roleId)
                        ?.name || ''
                    }
                  />
                ))
              ))}
            {!target.assignedToAll &&
              target.customFields?.map((customField) =>
                (customField.options?.length || 0) > 5 ? (
                  <TargetAssignmentCustomFieldChipList
                    key={customField.name}
                    Chip={CustomFieldChip}
                    hoverColor={purple[100]}
                    footerTop={footerTop}
                    customField={customField}
                  />
                ) : (
                  customField.options?.map((optionName) => (
                    <CustomFieldChip
                      key={customField.name + optionName}
                      labelText={optionName}
                    />
                  ))
                )
              )}
            {showBatchCheckingCriteria && (
              <DocumentCriteriaCardSection target={target} />
            )}
          </Grid>
        </StyledCardContent>
      </StyledCard>
    </CardContainer>
  );
}
