import React, { ReactElement, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { getI18n, useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import BusyOverlay from 'components/BusyOverlay';
import LoginPage from 'pages/LoginPage';
import Logout from 'pages/LogoutPage/LogoutPage';
import AdminAccessErrorPage from 'pages/AdminAccessErrorPage';
import PrivateContent from 'components/PrivateContent';
import ProtectedRoute from 'components/ProtectedRoute';
import AuthContext from 'contexts/AuthContext';
import useTitle from 'hooks/useTitle';
import useGetAuthenticationToken from 'hooks/useGetAuthenticationToken';

import theme from './theme';
import './App.scss';
import './i18n';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function App(): ReactElement {
  const { t } = useTranslation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  document.documentElement.lang = getI18n().language;

  document
    .querySelector<HTMLElement>('meta[name="description"]')
    ?.setAttribute('content', t('shared.app.name'));

  const { accessToken, isLoading } = useGetAuthenticationToken();

  useTitle(t('shared.app.name'));

  useEffect(() => {
    // Remove last logout notification reason value
    if (accessToken) {
      window.localStorage.removeItem('logout-reason');
    }
  }, [accessToken]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BusyOverlay open={isLoading} />
        {!isLoading && (
          <AuthContext.Provider value={[accessToken]}>
            <Router basename={process.env.PUBLIC_URL}>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route exact path="/logout" component={Logout} />
                <Route
                  exact
                  path="/admin-access-denied"
                  component={AdminAccessErrorPage}
                />
                <ProtectedRoute checkAuthorization={false}>
                  <QueryClientProvider client={queryClient}>
                    <PrivateContent />
                  </QueryClientProvider>
                </ProtectedRoute>
              </Switch>
            </Router>
          </AuthContext.Provider>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
