import React, { ReactElement, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Theme } from '@mui/material/styles';

import NavigationMenu from 'components/NavigationMenu';
import NotistackCustomSnackbarProvider from './components/NotistackCustomSnackbarProvider';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  AcrolinxLogo,
  AppBarTitle,
  DrawerPaper,
  FlexBox,
  HelpLink,
  MainContent,
  StyledAppBar,
  StyledBox,
  StyledIconButton,
  StyledToolbar,
  StyledToolbarBox,
} from './styles';
import { LogoutButton } from 'components/LogoutButton';
import CurrentUserContext from 'contexts/CurrentUserContext';

export const DRAWER_WIDTH = 300;

declare module 'notistack' {
  interface VariantOverrides {
    default: {
      snackbarContentProps?: React.HTMLAttributes<HTMLDivElement>;
    };
  }
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: ReactElement | ReactElement[];
}

export default function MainLayout(props: Props): ReactElement {
  const { window, children } = props;
  const hidden = useMediaQuery((themeMQ: Theme) =>
    themeMQ.breakpoints.up('lg')
  );
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { t } = useTranslation();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const user = useContext(CurrentUserContext);

  const supportPageLang = ['de', 'ja'].includes(i18n.language)
    ? i18n.language
    : 'en-us';

  return (
    <Fade in={true}>
      <FlexBox>
        <StyledAppBar position="fixed" color={'inherit'}>
          <StyledToolbar>
            <Box display="flex" alignItems="center" width={1}>
              <Box flexGrow={1}>
                {!hidden && (
                  <StyledIconButton
                    aria-label={t('shared.navigation.a11y.drawerOpen')}
                    onClick={handleDrawerToggle}
                    size="large"
                  >
                    <MenuIcon />
                  </StyledIconButton>
                )}
                {hidden && (
                  <Box display="flex" alignItems="center">
                    <Box pl={2}>
                      <AcrolinxLogo
                        title={t('shared.components.header.altLogo')}
                      />
                    </Box>
                    <Box>
                      <AppBarTitle variant="h6" noWrap color={'primary'}>
                        {t('shared.components.header.dashboard')}
                      </AppBarTitle>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box p={1}>
                <Typography variant="body2">
                  {t('shared.components.header.username')} {user?.username}
                </Typography>
              </Box>
              <Box p={1}>&middot;</Box>
              <Box
                m={1}
                mr={4}
                p={2 / 5}
                sx={{ '&:focus-within': { backgroundColor: 'primary.light' } }}
              >
                <HelpLink
                  href={`https://support.acrolinx.com/hc/${supportPageLang}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Typography variant="body2">
                    {t('shared.components.header.help')}
                  </Typography>
                </HelpLink>
              </Box>
              <Box p={1} pr={4}>
                <LogoutButton
                  text="shared.components.header.logoutButton"
                  variant="contained"
                />
              </Box>
            </Box>
          </StyledToolbar>
        </StyledAppBar>
        <StyledBox>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <DrawerPaper
            {...((hidden && {
              variant: 'permanent',
              open: true,
            }) || {
              container,
              variant: 'temporary',
              anchor: theme.direction === 'rtl' ? 'right' : 'left',
              open: mobileOpen,
              onClose: handleDrawerToggle,
              ModalProps: {
                keepMounted: true, // Better open performance on mobile.
              },
            })}
          >
            <Box>
              <StyledToolbarBox width={1} px={5}>
                {!hidden && (
                  <Box display="flex" alignItems="center" width={1}>
                    <Box flexGrow={1}>
                      <AcrolinxLogo
                        title={t('shared.components.header.altLogo')}
                      />
                    </Box>
                    <Box>
                      <StyledIconButton
                        aria-label={t('shared.navigation.a11y.drawerClose')}
                        onClick={handleDrawerToggle}
                        size="large"
                      >
                        <CloseIcon />
                      </StyledIconButton>
                    </Box>
                  </Box>
                )}
              </StyledToolbarBox>
              <Box p={2}>
                <NavigationMenu onClick={() => setMobileOpen(false)} />
              </Box>
            </Box>
          </DrawerPaper>
        </StyledBox>
        <Box
          flexGrow={1}
          style={{
            overflow: 'hidden',
          }}
        >
          <Toolbar />
          <NotistackCustomSnackbarProvider>
            <MainContent>{children}</MainContent>
          </NotistackCustomSnackbarProvider>
        </Box>
      </FlexBox>
    </Fade>
  );
}
