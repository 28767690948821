import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledNewBadgeBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.custom.mediumSpringGreen,
  borderRadius: theme.typography.pxToRem(6),
  '& .MuiTypography-body1': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
}));

const NewBadge = () => {
  const { t } = useTranslation();

  return (
    <StyledNewBadgeBox>
      <Typography fontSize={'12px'} letterSpacing={'0.24px'}>
        {t('shared.navigation.new')}
      </Typography>
    </StyledNewBadgeBox>
  );
};

export default NewBadge;
